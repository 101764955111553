import {ModuleWithProviders, NgModule} from "@angular/core";
import {ApiModule} from "./api";
import {apiConfigFactory} from "./api.config";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {BedagOpenIdConnectModule, BedagStyleModule} from "bedag-angular-components";
import {CurrentUserResolver} from "./resolvers/current-user.resolver";
import {IntegerDirective} from "./directives/integer.directive";
import { FullScreenSpinnerComponent } from './full-screen-spinner/full-screen-spinner.component';

/**
 * The core module contributes declarations and services used throughout the application.
 *
 * It is available in two flavors:
 * CoreModule, which can be imported by any module in the application,
 * and CoreModule.forRoot(), which must (only) be imported by the application module.
 *
 * The reason for this split is that angular uses different scoping rules for declarations and providers. Specifically,
 * declarations must be imported in every module, while providers can be inherited from parent injectors - and should,
 * since registering them again would create new independent instances. We therefore put our declarations in the
 * CoreModule, and our singleton providers into the CoreModuleForRoot.
 */
@NgModule({
  imports: [
    BedagStyleModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    IntegerDirective,
    FullScreenSpinnerComponent
  ],
  exports: [
    BedagStyleModule,
    FormsModule,
    ReactiveFormsModule,
    IntegerDirective,
    FullScreenSpinnerComponent
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModuleForRoot,
      // we had to define a separate module rather than inlining the providers here,
      // because BedagStyleModule.forRoot() does not make its providers accessible by means other than an import,
      // which can not be specified when using the ModuleWithProviders syntax ...
    }
  }
}

@NgModule({
  imports: [
    ApiModule.forRoot(apiConfigFactory),
    BedagOpenIdConnectModule.forRoot(),
    BedagStyleModule.forRoot(),
    HttpClientModule,

    CoreModule,
  ],
  exports: [CoreModule],
  providers: [
    CurrentUserResolver
  ]
})
export class CoreModuleForRoot {
}
