import { Routes } from '@angular/router';
import { BetriebeModule } from "./betriebe/betriebe.module";
import { HomeComponent } from './home/home.component';
import { BenutzerModule } from "./benutzer/benutzer.module";
import { BerechtigungModule } from "./berechtigung/berechtigung.module";
const ɵ0 = () => BenutzerModule, ɵ1 = () => BetriebeModule, ɵ2 = () => BerechtigungModule;
const routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'benutzer', loadChildren: ɵ0 },
    { path: 'betriebe', loadChildren: ɵ1 },
    { path: 'berechtigung', loadChildren: ɵ2 }
];
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2 };
