import { ApplikationEnum } from "../core/api/model/applikationEnum";
import { BenutzerRolleDTO } from "../core/api";
import { ApplicationService } from "./application.service";
import * as i0 from "@angular/core";
import * as i1 from "./application.service";
export class BerechtigungService {
    constructor(applikationService) {
        this.applikationService = applikationService;
    }
    getBenutzerApplikationen(benutzer) {
        let anwendungenAuswahl = Object.values(ApplikationEnum);
        if (!this.isBearbeitenderBenutzerAdmin(benutzer) && this.applikationService.isApplicationActive(ApplikationEnum.GEFPORTAL)) {
            anwendungenAuswahl = anwendungenAuswahl.filter(anwendung => anwendung != ApplikationEnum.GEFPORTAL);
        }
        if (!this.canManageEfoRoles(benutzer)) {
            anwendungenAuswahl = anwendungenAuswahl.filter(anwendung => anwendung != ApplikationEnum.EIGENEFORMELONLINE);
        }
        if (!this.canManageHPVRoles(benutzer)) {
            anwendungenAuswahl = anwendungenAuswahl.filter(anwendung => anwendung != ApplikationEnum.HPV);
        }
        return anwendungenAuswahl;
    }
    isBearbeitenderBenutzerAdmin(benutzer) {
        return benutzer.rollen.filter(r => r.rolle === BenutzerRolleDTO.RolleEnum.ADMINISTRATOR).length > 0;
    }
    canManageEfoRoles(benutzer) {
        return this.applikationService.isApplicationActive(ApplikationEnum.EIGENEFORMELONLINE) &&
            (this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.ADMINISTRATOR) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.EFOADMIN) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER));
    }
    canManageHPVRoles(benutzer) {
        return this.applikationService.isApplicationActive(ApplikationEnum.HPV) &&
            (this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.ADMINISTRATOR) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.HPVADMIN) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.HPVPRAXISADMINISTRATION) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.HPVPRAXISLEITUNG));
    }
    hasBenutzerRolle(benutzer, rolle) {
        return benutzer.rollen.map(rolle => rolle.rolle).includes(rolle);
    }
}
BerechtigungService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BerechtigungService_Factory() { return new BerechtigungService(i0.ɵɵinject(i1.ApplicationService)); }, token: BerechtigungService, providedIn: "root" });
