<div *ngIf="betrieb">
  <h1>{{betrieb.name1}}</h1>
  <div>
    <p class="intro" translate="betriebe.detail.titel.info"></p>
    <p>{{ betrieb.strasse}}
      <br/>
      {{betrieb.plz}} {{betrieb.ort}}
      <br/>
      {{ 'enum.betriebart.' + betrieb.betriebArt | translate}}</p>
    <br/>
    <div>
      <div class="fa fa-envelope-open">&nbsp;{{ 'betriebe.detail.titel.korrespondenzsprache' | translate }}:</div>
      <div>{{ 'enum.sprache.' + betrieb.korrespondenzSprache | translate}}</div>
    </div>
  </div>
  <div class='hr-dln-start'></div>
  <div *ngIf="(benutzers$ | async)?.length>0">
    <h2 translate="betriebe.detail.titel.mitarbeiter"></h2>
    <div class="w100 mb-2">
      <label for="anzahlBetriebe" class="label" translate="global.zeige"></label>
      <select
        class="select form-control"
        id="anzahlBetriebe"
        name="anzahlBetriebe" [(ngModel)]="itemsPerPage">
        <option [ngValue]="5">5</option>
        <option [ngValue]="10">10</option>
        <option [ngValue]="15">15</option>
        <option [ngValue]="20">20</option>
      </select>
      <div style="min-width: 270px;" class="form-group float-right">
        <label for="searchBetrieb" class="label-search" translate="global.suche"></label>
        <input class="form-control input-search" type="text" id="searchBetrieb" name="searchBetrieb" [(ngModel)]="filter">
      </div>
    </div>
    <table class="table">
      <thead>
      <tr>
        <th translate="betriebe.detail.tableheaders.email"></th>
        <th translate="betriebe.detail.tableheaders.name"></th>
        <th translate="betriebe.detail.tableheaders.vorname"></th>
        <th translate="betriebe.detail.tableheaders.rollen"></th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let currentBenutzer of benutzers$ | async | orderBy: key : reverse | filter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: page }">
        <tr>
          <td>{{currentBenutzer.email}}</td>
          <td>{{currentBenutzer.name}}</td>
          <td>{{currentBenutzer.vorname}}</td>
          <td>
            <div *ngFor="let rolle of currentBenutzer.rollen">{{rolle.betrieb?.name1}} ({{ 'benutzer.rolle.' + rolle.rolle | translate}})</div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
    <pagination-controls (pageChange)="page = $event" previousLabel="" class="pagination-control"
                         nextLabel=""></pagination-controls>
  </div>

</div>


<div style="margin-top: 20px">
  <button class='btn btn-primary' (click)="goBack()">
    <i class="fa fa-arrow-left" aria-hidden="true"></i>
    <span translate="betriebe.detail.button.zurueck"></span>
  </button>
</div>
