import { Component, OnInit } from '@angular/core';
import {JsonPipe} from "@angular/common";
import {AlertHolderService} from "./alert-holder.service";

@Component({
  selector: 'app-alert-list',
  template: `    
  <app-alert *ngFor="let alert of alertHolder.alerts" [type]="alert.type" (dismiss)="alertHolder.dismiss(alert)">
    {{alert.message}}
    <div style="margin-top:20px" *ngIf="alert.type === 'danger' && alert.error">
      <div class="form-group">
        <label for="error">{{'alert.error'|translate}}</label>
        <textarea class="form-control" name="error" rows="4" [ngModel]="getErrorString(alert.error)"></textarea>
      </div>
    </div>
  </app-alert>`

})

export class AlertListComponent {

  constructor(public alertHolder: AlertHolderService, public jsonPipe: JsonPipe) { }

  public getErrorString(error: any): string {
    try {
      let b = this.jsonPipe.transform(error);
      return b;
    } catch {
      return error;
    }
  }
}
