import {Injectable} from '@angular/core';
import {OAuthService} from "angular-oauth2-oidc";
import {AuthenticationService} from "../auth/auth.service";
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
/**
 * Service listening for Login / Logout - Events from OAuthService.
 *
 * For a SSO-Login with Identity-Provider 'BE-Login' we need to redirect the browser to a specific Logout-URL in order to invalidate the user-session after the SSO-Logout.
 * Unfortunately we have to do this workaround, since at the moment the SAML-Implementation for BE-Login does not support a Logout-functionality.
 *
 * */
export class LoginEventListenerService {

  private isBeLogin: boolean = false;

  constructor(private authService: AuthenticationService, private oauthService: OAuthService, private router: Router) {
    this.init();
  }

  private init(): void {
    //subscribe to login-event, so we can distinguish if user logged in with be-login or otherwise
    this.oauthService.events.subscribe(e => {
      if (e.type === 'token_received') {
        this.authService.userLoggedIn$.subscribe((isLoggedIn) => {
          if (isLoggedIn) {
            this.handleIdentityClaim();
          }
        });
      } else if (e.type === 'logout') {
        if (this.isBeLogin) {
          localStorage.setItem("clearBeLoginSession", "true");
          this.isBeLogin = false;
        }
      } else if (e.type === 'discovery_document_loaded') {
        /* If we already have identity-claims, e.g. after a refresh,
        /* we can use them for finding out if we are logged in via BE-Login */
        if (this.oauthService.getIdentityClaims()) {
          this.handleIdentityClaim();
        }
        if (localStorage.getItem("clearBeLoginSession") && !this.authService.isLoggedIn()) {
          this.isBeLogin = false;
          localStorage.removeItem("clearBeLoginSession");
          window.location.href = environment.beLoginLogoutUrl;
        }
      }
    });
  }

  /* Keycloak is mapped to return a userinfo-attribute 'idpname' if user is logged in
  /* via be-login (value 'be-login') or adfs (value 'adfs'). Login with a keycloak-user does not return this attribute */
  private handleIdentityClaim(): void {
    let identityClaims = this.oauthService.getIdentityClaims();
    if (identityClaims && identityClaims.hasOwnProperty("idpname")) {
      let ipd = identityClaims['idpname'];
      if (ipd == 'be-login') {
        this.isBeLogin = true;
      }
    } else {
      this.isBeLogin = false;
    }
  }
}
