/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./berechtigung-beantragen-hpv.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../berechtigung-beantragen-betriebe-search/berechtigung-beantragen-betriebe-search.component.ngfactory";
import * as i4 from "../berechtigung-beantragen-betriebe-search/berechtigung-beantragen-betriebe-search.component";
import * as i5 from "../../core/api/api/benutzer.service";
import * as i6 from "../../core/api/api/betriebe.service";
import * as i7 from "../../core/notification/notification.service";
import * as i8 from "@angular/common";
import * as i9 from "../../core/full-screen-spinner/full-screen-spinner.component.ngfactory";
import * as i10 from "../../core/full-screen-spinner/full-screen-spinner.component";
import * as i11 from "./berechtigung-beantragen-hpv.component";
import * as i12 from "@angular/router";
import * as i13 from "@ng-bootstrap/ng-bootstrap";
var styles_BerechtigungBeantragenHpvComponent = [i0.styles];
var RenderType_BerechtigungBeantragenHpvComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BerechtigungBeantragenHpvComponent, data: {} });
export { RenderType_BerechtigungBeantragenHpvComponent as RenderType_BerechtigungBeantragenHpvComponent };
function View_BerechtigungBeantragenHpvComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["\n    ", "\n  "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "app-berechtigung-beantragen-betriebe-search", [["applikation", "GSI_HPV"]], null, [[null, "onBeantrageBerechtigung"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onBeantrageBerechtigung" === en)) {
        var pd_0 = (_co.showSelectRoleDialog($event[0]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_BerechtigungBeantragenBetriebeSearchComponent_0, i3.RenderType_BerechtigungBeantragenBetriebeSearchComponent)), i1.ɵdid(7, 114688, null, 0, i4.BerechtigungBeantragenBetriebeSearchComponent, [i2.TranslateService, i5.BenutzerService, i6.BetriebeService, i7.NotificationService], { showGlnSearch: [0, "showGlnSearch"], applikation: [1, "applikation"] }, { onBeantrageBerechtigung: "onBeantrageBerechtigung" }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_1 = false; var currVal_2 = "GSI_HPV"; _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("berechtigung.beantragen.hpv.titel")); _ck(_v, 3, 0, currVal_0); }); }
export function View_BerechtigungBeantragenHpvComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BerechtigungBeantragenHpvComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(10, 0, null, null, 6, "button", [["class", "btn btn-primary"], ["style", "float: left; display: inline;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-arrow-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["translate", "berechtigung.beantragen.button.zurueck"]], null, null, null, null, null)), i1.ɵdid(15, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "app-fullscreen-spinner", [], null, null, null, i9.View_FullScreenSpinnerComponent_0, i9.RenderType_FullScreenSpinnerComponent)), i1.ɵdid(19, 49152, null, 0, i10.FullScreenSpinnerComponent, [], { transparentForDurationMs: [0, "transparentForDurationMs"], show: [1, "show"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.benutzer; _ck(_v, 1, 0, currVal_0); var currVal_2 = "berechtigung.beantragen.button.zurueck"; _ck(_v, 15, 0, currVal_2); var currVal_3 = 50; var currVal_4 = _co.loading; _ck(_v, 19, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("berechtigung.beantragen.hpv.hilfe_text")); _ck(_v, 5, 0, currVal_1); }); }
export function View_BerechtigungBeantragenHpvComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-berechtigung-beantragen-hpv", [], null, null, null, View_BerechtigungBeantragenHpvComponent_0, RenderType_BerechtigungBeantragenHpvComponent)), i1.ɵdid(1, 114688, null, 0, i11.BerechtigungBeantragenHpvComponent, [i2.TranslateService, i5.BenutzerService, i12.Router, i7.NotificationService, i13.NgbModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BerechtigungBeantragenHpvComponentNgFactory = i1.ɵccf("app-berechtigung-beantragen-hpv", i11.BerechtigungBeantragenHpvComponent, View_BerechtigungBeantragenHpvComponent_Host_0, {}, {}, []);
export { BerechtigungBeantragenHpvComponentNgFactory as BerechtigungBeantragenHpvComponentNgFactory };
