import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {BenutzerRolleDTO, BetriebDTO} from "../../core/api";
import RolleEnum = BenutzerRolleDTO.RolleEnum;
import {ApplikationEnum} from "../../core/api/model/applikationEnum";

@Component({
  selector: 'app-berechtigung-beantragen-select-role',
  templateUrl: './berechtigung-beantragen-select-role.component.html',
  styleUrls: ['./berechtigung-beantragen-select-role.component.scss']
})
export class BerechtigungBeantragenSelectRoleComponent implements OnInit {
  @Input() public betrieb: BetriebDTO;
  @Input() public applikation: ApplikationEnum;

  public RolleEnum: typeof RolleEnum = RolleEnum;

  public selectableRoles = [];
  public selectedRole: RolleEnum;
  public gln: string;

  constructor(public modal: NgbActiveModal, public translateService: TranslateService) { }

  ngOnInit() {
    if (this.applikation === ApplikationEnum.HPV) {
      this.selectableRoles = [RolleEnum.HPVPRAXISLEITUNG, RolleEnum.HPVPRAXISADMINISTRATION, RolleEnum.HPVPRAXISSACHBEARBEITER];
    } else if (this.applikation === ApplikationEnum.EIGENEFORMELONLINE) {
      this.selectableRoles = [RolleEnum.EFOBETRIEBSLEITER, RolleEnum.EFOBETRIEBSMITARBEITER];
    }
  }

  onSelectedRoleChanged() {
    this.gln = undefined;
  }
}
