<h2 translate="berechtigung.beantragen.unter.titel"></h2>
<form #searchBetriebForm2="ngForm" (ngSubmit)="searchBetrieb()">
  <div class="w100 form-group">
    <label for="name" [ngClass]="translateService.currentLang=='de' ? 'label': 'label label-french'"
           translate="berechtigung.beantragen.label.name"></label>
    <input
      id="name"
      name="name"
      class="form-control"
      [ngClass]="translateService.currentLang=='de' ? 'input': 'input input-french'"
      [(ngModel)]="searchBetriebDTO.name1">

    <label for="strasse" [ngClass]="translateService.currentLang=='de' ? 'label-2': 'label-2 label-french'"
           translate="berechtigung.beantragen.label.strasse">
    </label>
    <input
      id="strasse"
      name="strasse"
      class="form-control"
      [ngClass]="translateService.currentLang=='de' ? 'input': 'input input-french'"
      [(ngModel)]="searchBetriebDTO.strasse">
  </div>
  <div class="w100 form-group">
    <label for="ort" [ngClass]="translateService.currentLang=='de' ? 'label': 'label label-french'"
           translate="berechtigung.beantragen.label.ort"></label>
    <input
      id="ort"
      name="ort"
      class="form-control"
      [ngClass]="translateService.currentLang=='de' ? 'input': 'input input-french'"
      [(ngModel)]="searchBetriebDTO.ort">

    <ng-container *ngIf="showGlnSearch">
      <label for="gln" [ngClass]="translateService.currentLang=='de' ? 'label-2': 'label-2 label-french'"
             translate="berechtigung.beantragen.label.gln"></label>
      <input
        id="gln"
        name="gln"
        class="form-control"
        [ngClass]="translateService.currentLang=='de' ? 'input': 'input input-french'"
        [(ngModel)]="searchBetriebDTO.gln">
    </ng-container>

    <input type="submit" class="btn btn-primary" [ngClass]="translateService.currentLang=='de' ? 'button': 'button button-french'"
           value="{{'berechtigung.beantragen.button.suche' | translate }}">
  </div>

  <br>
</form>
<br>
<div *ngIf="betriebe">
  <table class="table">
    <thead>
    <tr>
      <th *ngIf="showGlnSearch" (click)="sort('gln')" translate="berechtigung.beantragen.label.gln"> <span *ngIf="key =='gln'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"> </span></th>
      <th (click)="sort('name1')" translate="berechtigung.beantragen.label.name"> <span *ngIf="key =='name1'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th (click)="sort('strasse')" translate="berechtigung.beantragen.label.strasse"> <span *ngIf="key =='strasse'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th (click)="sort('ort')" translate="berechtigung.beantragen.label.ort"> <span *ngIf="key =='ort'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"> </span></th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let betrieb of betriebe | orderBy: key : reverse | filter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: page }">
      <tr>
        <td *ngIf="showGlnSearch">{{betrieb?.gln}}</td>
        <td>{{betrieb?.name1}}</td>
        <td>{{betrieb?.strasse}}</td>
        <td>{{betrieb?.ort}}</td>
        <td>
          <button
            class="btn btn-primary"
            (click)="beantrageBerechtigungBetrieb(betrieb)"
            translate="berechtigung.beantragen.button.beantragen"></button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <pagination-controls (pageChange)="page = $event" previousLabel="" class="pagination-control"
                       nextLabel=""></pagination-controls>
</div>
