<app-header></app-header>

<div class="bsg-content-wrapper">

  <main id="content" role="main">
    <app-alert-list></app-alert-list>
    <router-outlet></router-outlet>
  </main>

</div>
<app-footer></app-footer>
