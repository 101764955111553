<div class="content mr-2" *ngIf="authService.isLoggedIn()">
  <h1 translate="betriebe.list.titel"></h1>
  <app-applikation-selection (onApplikationChangeEvent)="onApplikationChanged($event[0])" [hiddenApplikationen]="hiddenApplikationen"></app-applikation-selection>
  <div class="w100 mb-2">
    <label for="anzahlBetriebe" class="label" translate="global.zeige"></label>
    <select
      class="select form-control"
      id="anzahlBetriebe"
      name="anzahlBetriebe" [(ngModel)]="itemsPerPage">
      <option [ngValue]="5">5</option>
      <option [ngValue]="10">10</option>
      <option [ngValue]="15">15</option>
      <option [ngValue]="20">20</option>
    </select>
    <div style="min-width: 270px;" class="form-group float-right" [hidden]="!isBenutzerAdmin()">
      <label for="searchBetrieb" class="label-search" translate="global.suche"></label>
      <input class="form-control input-search" type="text" id="searchBetrieb" name="searchBetrieb" [(ngModel)]="filter">
    </div>
  </div>
  <table class="table">
    <thead>
    <tr>
      <th (click)="sort('name1')" translate="betriebe.list.tableheaders.name"> <span *ngIf="key =='name1'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th (click)="sort('strasse')" translate="betriebe.list.tableheaders.strasse"> <span *ngIf="key =='strasse'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th (click)="sort('plz')" translate="betriebe.list.tableheaders.plz"> <span *ngIf="key =='plz'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th (click)="sort('ort')" translate="betriebe.list.tableheaders.ort"> <span *ngIf="key =='ort'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th (click)="sort('betriebArt')" translate="betriebe.list.tableheaders.art"> <span *ngIf="key =='betriebArt'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th>&nbsp;</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let betrieb of betriebe | orderBy: key : reverse | filter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: page }">
      <tr>
        <td>{{betrieb.name1}}</td>
        <td>{{betrieb.strasse}}</td>
        <td>{{betrieb.plz}}</td>
        <td>{{betrieb.ort}}</td>
        <td>{{betrieb.betriebArtTranslation}}</td>
        <td>
          <img
            src="./assets/img/flags/{{betrieb.korrespondenzSprache}}.png"
            title="{{ 'enum.sprache.' + betrieb.korrespondenzSprache | translate}}">
        </td>
        <td>
          <button class="btn btn-primary btn-sm" (click)="showDetails(betrieb)">
            <i class="fa fa-eye" aria-hidden="true" title="{{ 'betriebe.list.details' | translate}}"></i>
          </button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <pagination-controls (pageChange)="page = $event" previousLabel="" class="pagination-control"
                       nextLabel=""></pagination-controls>
</div>
