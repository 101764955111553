<ng-container *ngIf="authService.isLoggedIn()">
  <h1 translate="benutzer.liste.titel"></h1>
  <app-applikation-selection (onApplikationChangeEvent)="onApplikationChanged($event[0])"></app-applikation-selection>
  <div class="w100 mb-2">
    <label for="anzahlResultate" class="label" translate="global.zeige"></label>
    <select
      class="select form-control"
      id="anzahlResultate"
      name="anzahlResultate" [(ngModel)]="itemsPerPage">
      <option [ngValue]="5">5</option>
      <option [ngValue]="10">10</option>
      <option [ngValue]="15">15</option>
      <option [ngValue]="20">20</option>
    </select>
    <div style="min-width: 270px;" class="form-group float-right">
      <label for="searchBenutzer" class="label-search" translate="global.suche"></label>
      <input class="form-control input-search" type="text" id="searchBenutzer" name="searchBenutzer" [(ngModel)]="filter">
    </div>
  </div>
  <table class="table">
    <thead>
    <tr>
      <th class="w15" (click)="sort('benutzerName')" translate="benutzer.table.benutzername"> <span *ngIf="key =='benutzerName'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th class="w10" (click)="sort('name')" translate="benutzer.table.name"> <span *ngIf="key =='name'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th class="w10" (click)="sort('vorname')" translate="benutzer.table.vorname"> <span *ngIf="key =='vorname'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th class="w15" (click)="sort('email')" translate="benutzer.table.email"> <span *ngIf="key =='email'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th class="w20" (click)="sort('rolle')" translate="benutzer.table.rolle"> <span *ngIf="key =='rolle'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th class="w10" (click)="sort('letzterZugriff')" translate="benutzer.table.letzterZugriff"> <span *ngIf="key =='letzterZugriff'" [ngClass]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"></span></th>
      <th class="w7"></th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let currentBenutzer of benutzer$ | async | orderBy: key : reverse | filter: filter | paginate: { itemsPerPage: itemsPerPage, currentPage: page }">
      <tr [ngClass]="{ 'warning': currentBenutzer.rollen.length == 0 }">
        <td class="w15">{{currentBenutzer.benutzerName}}</td>
        <td class="w10">{{currentBenutzer.name}}</td>
        <td class="w10">{{currentBenutzer.vorname}}</td>
        <td class="w15">{{currentBenutzer.email}}</td>
        <td class="w20">
          <div *ngFor="let rolle of currentBenutzer.rollen">{{ 'benutzer.rolle.' + rolle.rolle | translate}} <span *ngIf="rolle.betrieb">({{rolle.betrieb?.name1}})</span></div>
        </td>
        <td class="w10">{{currentBenutzer.letzterZugriff | date: 'dd.MM.yyyy, HH:mm:ss'}}</td>
        <td class="w7" style="min-width: 80px;">
          <button class="btn btn-primary btn-sm" (click)="showDetails(currentBenutzer)" title="{{ 'benutzer.table.details' | translate }}"><i class="fas fa-user-alt" aria-hidden="true"></i></button>
          <button class="btn btn-primary btn-sm" (click)="showDialog(currentBenutzer)" *ngIf="isBearbeitenderBenutzerAdminImPortal()"><i class="far fa-trash-alt" aria-hidden="true"></i></button>
          <app-confirm-dialog></app-confirm-dialog>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <pagination-controls (pageChange)="page = $event" previousLabel="" class="pagination-control"
                       nextLabel=""></pagination-controls>
</ng-container>
