import {Injectable} from '@angular/core';
import {AlertHolderService} from "./alert-holder.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private alertService: AlertHolderService;
  private translateService: TranslateService;

  constructor(alertHolderService: AlertHolderService, translateService: TranslateService) {
    this.alertService = alertHolderService;
    this.translateService = translateService;
  }

  public handleErrorExtensive(messageKey: string, error: Error, interpolateParams?: Object): void {
    this.alertService.danger(this.translateService.instant(messageKey, interpolateParams), error);
  }

  public handleErrorShort(messageKey: string, interpolateParams?: Object): void {
    this.alertService.danger(this.translateService.instant(messageKey, interpolateParams), null);
  }

  public handleSuccess(messageKey: string, interpolateParams?: Object) {
    this.alertService.success(this.translateService.instant(messageKey, interpolateParams));
  }

  addErrorMessage(messageKey: string, interpolateParams?: Object) {
    let message = this.translateService.instant(messageKey, interpolateParams);
    this.alertService.danger(message, null);
  }


  addWarnMessage(messageKey: string, interpolateParams?: Object) {
    let message = this.translateService.instant(messageKey, interpolateParams);
    this.alertService.danger(message, null);
  }
}
