import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-alert',
  template: `
    <div class="alert fade alert-{{type}}" [class.alert-dismissible]="dismissible" [class.show]="!fading" role="alert">
      <button type="button" class="close" [attr.aria-label]="'Close' | translate"
              (click)="fading = true; dismiss.emit();" *ngIf="dismissible">
        <span aria-hidden="true">&times;</span>
      </button>
      <span class="fas {{icons[type]}} mr-2"></span>
      <ng-content></ng-content>
    </div>
  `

})
export class AlertComponent {

  @Input()
  type:string;

  @Output()
  dismiss = new EventEmitter<void>();

  fading = false;

  icons = {
    danger: "fa-exclamation-circle",
    warning: "fa-exclamation-triangle",
    info: "fa-info-circle",
    success: "fa-check-circle"
  };

  get dismissible() {
    return this.dismiss.observers.length;
  }


}
