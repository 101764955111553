import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BenutzerDTO, BenutzerService} from "../../core/api";
import {BerechtigungService} from "../berechtigung.service";
import {ApplikationEnum} from "../../core/api/model/applikationEnum";

@Component({
  selector: 'app-applikation-selection',
  templateUrl: './applikation-selection.component.html',
  styleUrls: ['./applikation-selection.component.scss']
})
export class ApplikationSelectionComponent implements OnInit {

  @Input() hiddenApplikationen: Array<string>;

  @Output() onApplikationChangeEvent: EventEmitter<any> = new EventEmitter();

  private benutzer: BenutzerDTO;

  public benutzerApplikationen: Array<string>;
  public selectedApplikation: string;
  private storageKey = 'selected-application';

  constructor(private benutzerService: BenutzerService, private berechtigungsService: BerechtigungService) {
  }

  ngOnInit() {
    this.benutzerService.self().subscribe((benutzerDTO) => {
      this.benutzer = benutzerDTO;
      this.benutzerApplikationen = this.berechtigungsService.getBenutzerApplikationen(benutzerDTO);
      this.benutzerApplikationen = this.filterHiddenApplikationen();
      if (this.benutzerApplikationen.length >= 1) {
        if (!this.preselectApplikation()) {
          this.selectedApplikation = this.benutzerApplikationen[0];
        }
        this.onApplikationChanged(this.selectedApplikation);
      }
    });
  }

  filterHiddenApplikationen(): Array<string> {
    if (!this.hiddenApplikationen)
      return this.benutzerApplikationen;
    return this.benutzerApplikationen.filter(applikation => !this.hiddenApplikationen.includes(applikation));
  }

  onApplikationChanged(selectedApplikation: string) {
    let selectedApplikationEnumKey = Object.keys(ApplikationEnum).find(key => ApplikationEnum[key] === selectedApplikation);
    this.onApplikationChangeEvent.emit([ApplikationEnum[selectedApplikationEnumKey]]);
    sessionStorage.setItem(this.storageKey, selectedApplikation);
  }

  // returns true if an application could be preselected
  private preselectApplikation(): boolean {
    const previouslySelectedApplikation = sessionStorage.getItem(this.storageKey);
    if (previouslySelectedApplikation) {
      if (this.benutzerApplikationen && this.benutzerApplikationen.indexOf(previouslySelectedApplikation) > -1) {
        this.selectedApplikation = previouslySelectedApplikation;
        return true;
      }
    }
    return false;
  }
}
