import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from "@angular/core";
import {RouterModule, Routes} from '@angular/router';

import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {AppHeaderComponent} from "./app-header.component";
import {AppNavComponent} from "./app-nav.component";
import {BedagStyleModule} from 'bedag-angular-components';
import {TranslateModule} from '@ngx-translate/core';
import {BetriebeModule} from "./betriebe/betriebe.module";
import {AppFooterComponent} from './app-footer/app-footer.component';
import {HomeComponent} from './home/home.component';
import {BenutzerModule} from "./benutzer/benutzer.module";
import {ConfirmDialogModule} from "./core/confirm-dialog/confirm-dialog.module";
import {BerechtigungModule} from "./berechtigung/berechtigung.module";
import {NotificationModule} from "./core/notification/notification.module";

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'benutzer', loadChildren: () => BenutzerModule},
  {path: 'betriebe', loadChildren: () => BetriebeModule},
  {path: 'berechtigung', loadChildren: () => BerechtigungModule}
];

@NgModule({
  bootstrap: [AppComponent],
  imports: [
    TranslateModule.forRoot(),
    BrowserModule,
    RouterModule.forRoot(routes),
    CoreModule.forRoot(),
    BedagStyleModule.forRoot(),
    BetriebeModule,
    BenutzerModule,
    BerechtigungModule,
    ConfirmDialogModule,
    NotificationModule
  ],
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppNavComponent,
    AppFooterComponent,
    HomeComponent
  ]
})
export class AppModule {
}
