/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/forms";
import * as i3 from "./alert.component.ngfactory";
import * as i4 from "./alert.component";
import * as i5 from "@angular/common";
import * as i6 from "./alert-list.component";
import * as i7 from "./alert-holder.service";
var styles_AlertListComponent = [];
var RenderType_AlertListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertListComponent, data: {} });
export { RenderType_AlertListComponent as RenderType_AlertListComponent };
function View_AlertListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 15, "div", [["style", "margin-top:20px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(2, 0, null, null, 12, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "label", [["for", "error"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(8, 0, null, null, 5, "textarea", [["class", "form-control"], ["name", "error"], ["rows", "4"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 9).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 9)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 9)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(11, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, null), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(13, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵted(-1, null, ["\n    "]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = "error"; var currVal_9 = _co.getErrorString(_v.parent.context.$implicit.error); _ck(_v, 11, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform("alert.error")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 13).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 13).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 13).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 13).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 13).ngClassValid; var currVal_6 = i0.ɵnov(_v, 13).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 13).ngClassPending; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_AlertListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-alert", [], null, [[null, "dismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dismiss" === en)) {
        var pd_0 = (_co.alertHolder.dismiss(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AlertComponent_0, i3.RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i4.AlertComponent, [], { type: [0, "type"] }, { dismiss: "dismiss" }), (_l()(), i0.ɵted(2, 0, ["\n    ", "\n    "])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AlertListComponent_2)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n  "]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.type; _ck(_v, 1, 0, currVal_0); var currVal_2 = ((_v.context.$implicit.type === "danger") && _v.context.$implicit.error); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.message; _ck(_v, 2, 0, currVal_1); }); }
export function View_AlertListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["    \n  "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertListComponent_1)), i0.ɵdid(2, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertHolder.alerts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AlertListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-alert-list", [], null, null, null, View_AlertListComponent_0, RenderType_AlertListComponent)), i0.ɵdid(1, 49152, null, 0, i6.AlertListComponent, [i7.AlertHolderService, i5.JsonPipe], null, null)], null, null); }
var AlertListComponentNgFactory = i0.ɵccf("app-alert-list", i6.AlertListComponent, View_AlertListComponent_Host_0, {}, {}, []);
export { AlertListComponentNgFactory as AlertListComponentNgFactory };
