import {Injectable} from '@angular/core';
import {ApplikationEnum} from "../core/api/model/applikationEnum";
import {BenutzerDTO, BenutzerRolleDTO} from "../core/api";
import {ApplicationService} from "./application.service";

@Injectable({
  providedIn: 'root'
})
export class BerechtigungService {

  constructor(private applikationService: ApplicationService) {
  }

  public getBenutzerApplikationen(benutzer: BenutzerDTO): Array<string> {
    let anwendungenAuswahl = Object.values(ApplikationEnum);
    if (!this.isBearbeitenderBenutzerAdmin(benutzer) && this.applikationService.isApplicationActive(ApplikationEnum.GEFPORTAL)) {
      anwendungenAuswahl = anwendungenAuswahl.filter(anwendung => anwendung != ApplikationEnum.GEFPORTAL);
    }
    if (!this.canManageEfoRoles(benutzer)) {
      anwendungenAuswahl = anwendungenAuswahl.filter(anwendung => anwendung != ApplikationEnum.EIGENEFORMELONLINE);
    }
    if (!this.canManageHPVRoles(benutzer)) {
      anwendungenAuswahl = anwendungenAuswahl.filter(anwendung => anwendung != ApplikationEnum.HPV);
    }
    return anwendungenAuswahl;
  }

  private isBearbeitenderBenutzerAdmin(benutzer: BenutzerDTO): boolean {
    return benutzer.rollen.filter(
      r => r.rolle === BenutzerRolleDTO.RolleEnum.ADMINISTRATOR
    ).length > 0;
  }

  private canManageEfoRoles(benutzer: BenutzerDTO): boolean {
    return this.applikationService.isApplicationActive(ApplikationEnum.EIGENEFORMELONLINE) &&
      (this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.ADMINISTRATOR) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.EFOADMIN) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER));
  }

  private canManageHPVRoles(benutzer: BenutzerDTO): boolean {
    return this.applikationService.isApplicationActive(ApplikationEnum.HPV) &&
      (this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.ADMINISTRATOR) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.HPVADMIN) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.HPVPRAXISADMINISTRATION) || this.hasBenutzerRolle(benutzer, BenutzerRolleDTO.RolleEnum.HPVPRAXISLEITUNG));
  }

  private hasBenutzerRolle(benutzer: BenutzerDTO, rolle: BenutzerRolleDTO.RolleEnum): boolean {
    return benutzer.rollen.map(rolle => rolle.rolle).includes(rolle);
  }
}
