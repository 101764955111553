import * as i0 from "@angular/core";
export class AlertHolderService {
    constructor() {
        this.alerts = [];
    }
    success(message) {
        this.add({ type: "success", message, error: null });
    }
    info(message) {
        this.add({ type: "info", message, error: null });
    }
    warning(message) {
        this.add({ type: "warning", message, error: null });
    }
    danger(message, error) {
        this.add({ type: "danger", message, error });
    }
    add(alert) {
        this.alerts.push(alert);
        if (alert.type === "success" || alert.type === "info") {
            setTimeout(() => this.dismiss(alert), 5000);
        }
    }
    dismiss(alert) {
        setTimeout(() => this.alerts = this.alerts.filter(a => a !== alert), 150);
    }
}
AlertHolderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertHolderService_Factory() { return new AlertHolderService(); }, token: AlertHolderService, providedIn: "root" });
