/**
 * GEF Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateRolleDTO { 
    benutzerId: object;
    betriebId?: object;
    rolle: CreateRolleDTO.RolleEnum;
    gln?: string;
}
export namespace CreateRolleDTO {
    export type RolleEnum = 'SYSTEM' | 'ADMINISTRATOR' | 'EFO_ADMIN' | 'EFO_BETRIEBSLEITER' | 'EFO_BETRIEBSMITARBEITER' | 'HPV_ADMIN' | 'HPV_PRAXISLEITUNG' | 'HPV_PRAXISADMINISTRATION' | 'HPV_PRAXISSACHBEARBEITER';
    export const RolleEnum = {
        SYSTEM: 'SYSTEM' as RolleEnum,
        ADMINISTRATOR: 'ADMINISTRATOR' as RolleEnum,
        EFOADMIN: 'EFO_ADMIN' as RolleEnum,
        EFOBETRIEBSLEITER: 'EFO_BETRIEBSLEITER' as RolleEnum,
        EFOBETRIEBSMITARBEITER: 'EFO_BETRIEBSMITARBEITER' as RolleEnum,
        HPVADMIN: 'HPV_ADMIN' as RolleEnum,
        HPVPRAXISLEITUNG: 'HPV_PRAXISLEITUNG' as RolleEnum,
        HPVPRAXISADMINISTRATION: 'HPV_PRAXISADMINISTRATION' as RolleEnum,
        HPVPRAXISSACHBEARBEITER: 'HPV_PRAXISSACHBEARBEITER' as RolleEnum
    };
}

