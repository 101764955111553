import {Injectable} from '@angular/core';
import {BenutzerDTO, BenutzerRolleDTO, BenutzerService} from "../core/api";
import {LoginService} from "bedag-angular-components";
import {OAuthService} from "angular-oauth2-oidc";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {Observable, of} from "rxjs";
import {NotificationService} from "../core/notification/notification.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends LoginService {

  private benutzer: BenutzerDTO;
  private userDetails: any;
  private loading: boolean = false;
  public userLoggedIn$: Observable<boolean>;

  private EFO_ADMIN_AND_BETRIEBSLEITER: BenutzerRolleDTO.RolleEnum[] = [BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER, BenutzerRolleDTO.RolleEnum.EFOADMIN];
  private HPV_ADMININSTRATION_AND_PRAXISLEITUNG: BenutzerRolleDTO.RolleEnum[] = [BenutzerRolleDTO.RolleEnum.HPVADMIN, BenutzerRolleDTO.RolleEnum.HPVPRAXISADMINISTRATION, BenutzerRolleDTO.RolleEnum.HPVPRAXISLEITUNG];
  private EFO_ROLLEN: BenutzerRolleDTO.RolleEnum[] = [BenutzerRolleDTO.RolleEnum.EFOBETRIEBSMITARBEITER, BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER, BenutzerRolleDTO.RolleEnum.EFOADMIN];
  private HPV_ROLLEN: BenutzerRolleDTO.RolleEnum[] = [BenutzerRolleDTO.RolleEnum.HPVADMIN, BenutzerRolleDTO.RolleEnum.HPVPRAXISLEITUNG, BenutzerRolleDTO.RolleEnum.HPVPRAXISSACHBEARBEITER, BenutzerRolleDTO.RolleEnum.HPVPRAXISADMINISTRATION];
  private SUPERUSER: BenutzerRolleDTO.RolleEnum[] = [BenutzerRolleDTO.RolleEnum.ADMINISTRATOR];
  private ADMINISTRATOR: BenutzerRolleDTO.RolleEnum[] = [BenutzerRolleDTO.RolleEnum.ADMINISTRATOR, BenutzerRolleDTO.RolleEnum.EFOADMIN, BenutzerRolleDTO.RolleEnum.HPVADMIN];

  constructor(oauth: OAuthService, router: Router, location: Location, private benutzerService: BenutzerService, private oauthService: OAuthService, private notificationService: NotificationService) {
    super(oauth, router, location);

    this.userLoggedIn$ = new Observable(subscriber => {
      subscriber.next(this.userLoggedIn);
      let previousState = this.userLoggedIn;
      setInterval(() => {
        if (previousState !== this.userLoggedIn) {
          previousState = this.userLoggedIn;
          subscriber.next(this.userLoggedIn);
        }
      }, 100);
    });
  }

  public logout() {
    this.userDetails = null;
    this.benutzer = null;
    super.logout();
  }

  public login(path?: string): Promise<true> {
    return super.login(path).then((result: true) => {
      return result;
    });
  }

  public isLoggedIn(): boolean {
    return this.userLoggedIn;
  }

  private benutzerHasRolle(benutzerRollen: BenutzerRolleDTO[], acceptedRoles: BenutzerRolleDTO.RolleEnum[]): boolean {
    return (benutzerRollen.filter(benutzerRolle => {
      return acceptedRoles.includes(benutzerRolle.rolle);
    }).length > 0);
  }

  public canViewEfoKachel(): Observable<boolean> {
    return this.hasAnyRolle(this.EFO_ROLLEN);
  }

  public canViewHPVKachel(): Observable<boolean> {
    return this.hasAnyRolle(this.HPV_ROLLEN);
  }

  public isAdminOrLeitung(): Observable<boolean> {
    return this.hasAnyRolle(this.EFO_ADMIN_AND_BETRIEBSLEITER.concat(this.HPV_ADMININSTRATION_AND_PRAXISLEITUNG));
  }

  public isAdministrator(): Observable<boolean> {
    return this.hasAnyRolle(this.ADMINISTRATOR);
  }

  private hasAnyRolle(rollen: BenutzerRolleDTO.RolleEnum[]): Observable<boolean> {
    if (!this.isLoggedIn()) {
      return of(false);
    }
    if (this.benutzer == null && !this.loading) {
      this.loading = true;
      this.benutzerService.self().subscribe((benutzer) => {
        this.benutzer = benutzer;
        this.loading = false;
        return false;
      }, error => this.notificationService.handleErrorShort("error.benutzer.self"));
    }
    if (this.benutzer != null) {
      if (this.benutzerHasRolle(this.benutzer.rollen, this.SUPERUSER.concat(rollen))) {
        return of(true);
      }
      return of(false);
    }
    return of(false);
  }

  public relogin() {
    this.oauthService.initImplicitFlow();
  }

}
