<ng-container *ngIf="show">
  <div class="modal-backdrop fade" [ngClass]="{'show': !transparent}">
  </div>
  <div class="modal fade show d-block">
    <div class="modal-dialog modal-dialog-centered">
      <span class="fa-3x m-auto fas fa-spinner fa-spin fa-fw" *ngIf="!transparent"></span>
    </div>
  </div>
</ng-container>

