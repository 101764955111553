<div class="modal-header" style="color: black;">
  <h5 class="modal-title" id="modal-basic-title" translate>berechtigung.beantragen.rolle.dialog.title</h5>
  <button type="button" class="close" style="color: black;" (click)="modal.dismiss()">
    <i class="fas fa-times"></i>
  </button>
</div>
<div class="modal-body">
  <form #roleForm="ngForm">
    <div class="card" style="width: 36rem;">
      <div class="card-body">
        <h5 class="card-title" translate>berechtigung.beantragen.rolle.dialog.betrieb</h5>
        <p class="card-text">{{betrieb.name1}}</p>
        <p class="card-text">{{betrieb.strasse}}</p>
        <p class="card-text">{{betrieb.plz}}&nbsp;{{betrieb.ort}}</p>
      </div>
    </div>

    <div class="w100 form-group mt-5">
      <label for="role" [ngClass]="translateService.currentLang=='de' ? 'label': 'label label-french'"
             translate="berechtigung.beantragen.rolle.dialog.waehlen"></label>

      <select
        id="role"
        name="role"
        class="form-control"
        [ngClass]="translateService.currentLang=='de' ? 'input': 'input input-french'"
        [(ngModel)]="selectedRole"
        (ngModelChange)="onSelectedRoleChanged()">
        <option></option>
        <option *ngFor="let rolle of selectableRoles" [ngValue]="rolle">{{'benutzer.rolle.' + rolle | translate}}</option>
      </select>
    </div>

    <div class="w100 form-group" *ngIf="selectedRole === RolleEnum.HPVPRAXISLEITUNG">
      <label for="glnInput" [ngClass]="translateService.currentLang=='de' ? 'label': 'label label-french'"
             translate="berechtigung.beantragen.rolle.dialog.gln">
      </label>
      <input
        id="glnInput"
        name="glnInput"
        class="form-control"
        minlength="13" maxlength="13"
        integer autocomplete="off"
        [ngClass]="translateService.currentLang=='de' ? 'input': 'input input-french'"
        [(ngModel)]="gln">
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" [disabled]="!selectedRole || roleForm.invalid" (click)="modal.close({role: selectedRole, gln: gln})" translate>berechtigung.beantragen.rolle.dialog.send</button>
</div>
