<form *ngIf="bearbeitenderBenutzer && canManagePortalRollen()">
  <div>
    <label class="label" for="appRolle" translate="berechtigung.verwalten.label.rolle"></label>
    <select
      id="appRolle"
      name="appRolle"
      class="form-control input"
      [(ngModel)]="applikationsRolle">
      <option></option>
      <option *ngFor="let rolle of applikationsRollenAuswahl" [ngValue]="rolle">{{"benutzer.rolle." + rolle | translate }}</option>
    </select>
  </div>
  <button
    [disabled]="doesBenutzerAlreadyHaveApplikationsRolle() || applikationsRolle == ''"
    class="btn btn-primary"
    style="display: inline;"
    translate="berechtigung.verwalten.button.speichern"
    *ngIf="applikationsRolle !== undefined && applikationsRolle.rolle !== ''"
    (click)="saveApplikationsRolle()">
  </button>
</form>
<br>
<!-- List of Benutzer-Portalrollen -->
<table class="table">
  <thead>
  <tr>
    <th translate="berechtigung.verwalten.label.anwendung"></th>
    <th translate="berechtigung.verwalten.label.rolle"></th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngIf="rollenListe.length == 0">
    <td></td>
    <td translate="berechtigung.verwalten.keine.berechtigungen"></td>
    <td></td>
  </tr>
  <tr *ngFor="let rolle of rollenListe">
    <td>{{ 'anwendung.GEF_PORTAL' | translate }}</td>
    <td>{{ 'benutzer.rolle.' + rolle.rolle | translate}}</td>
    <td>
      <button
        class="btn btn-primary btn-sm"
        (click)="bestaetigeLoeschen(rolle)" *ngIf="canManagePortalRollen()">
        <i class="far fa-trash-alt"></i></button>
    </td>
  </tr>
  </tbody>
</table>
