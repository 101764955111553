import {Component, Input, OnInit} from "@angular/core";
import {AlertHolder} from "bedag-angular-components";
import {environment} from 'src/environments/environment.prod';
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationService} from "./auth/auth.service";
import {NavigationStart, Router} from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

  private languageNames = {
    'en': 'English',
    'de': 'Deutsch',
    'fr': 'Français',
    'it': 'Italiano',
    'rm': 'Rumantsch Grischun',
  };

  @Input('languages') languages: string[] = environment.availableLanguages;

  public show: boolean = false;

  constructor(public alerts: AlertHolder, public translateService: TranslateService, public authService: AuthenticationService, private router: Router) {
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        this.show = false;
      }
    });
  }

  ngOnInit(): void {
    for (const lang of this.languages) {
      this.translateService.setTranslation(lang, lang, true);
    }
  }

  public languageName(language: string) {
    return this.languageNames[language] || language;
  }

  public setLanguage(language: string) {
    localStorage.setItem('language', language);
    this.translateService.use(language);
  }

  public toggleMobileNav() {
    this.show = !this.show;
  }

}
