import {NgModule} from '@angular/core';
import {CommonModule, JsonPipe} from '@angular/common';
import {AlertComponent} from "./alert.component";
import {AlertListComponent} from "./alert-list.component";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    AlertComponent,
    AlertListComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot(),
    FormsModule
  ],
  exports: [
    AlertComponent,
    AlertListComponent
  ],
  providers:[
    JsonPipe
  ]
})
export class NotificationModule { }
