import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {BenutzerDTO, BenutzerRolleDTO, BenutzerService, BetriebDTO, BetriebeService, CreateRolleDTO} from "../../core/api";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";
import {ConfirmDialogService} from "../../core/confirm-dialog/confirm-dialog.service";
import {NotificationService} from "../../core/notification/notification.service";
import {BetriebRolle} from "../betrieb-rolle";

@Component({
  selector: 'app-rollen-verwalten-hpv',
  templateUrl: './rollen-verwalten-hpv.component.html',
  styleUrls: ['./rollen-verwalten-hpv.component.scss']
})
export class RollenVerwaltenHpvComponent implements OnInit, OnChanges {

  @Input() public benutzer: BenutzerDTO;

  @Input() public blockInput: boolean;

  @Input() public bearbeitenderBenutzer: BenutzerDTO;

  @Output() onDeleteRolle: EventEmitter<any> = new EventEmitter();

  public hpvRollenAuswahl: Array<CreateRolleDTO.RolleEnum> = new Array<CreateRolleDTO.RolleEnum>();
  public selectedRolle: CreateRolleDTO.RolleEnum;
  public benutzerHpvRollen: Array<BenutzerRolleDTO> = new Array<BenutzerRolleDTO>();
  public HPV_ROLLEN = CreateRolleDTO;
  public gln: string;

  itemsPerPage: number = 10;
  page: number = 1;
  filter: String;

  constructor(public translateService: TranslateService, private benutzerService: BenutzerService, private route: ActivatedRoute, private betriebService: BetriebeService,
              private confirmDialogService: ConfirmDialogService, private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.hpvRollenAuswahl.push(CreateRolleDTO.RolleEnum.HPVPRAXISADMINISTRATION);
    this.hpvRollenAuswahl.push(CreateRolleDTO.RolleEnum.HPVPRAXISLEITUNG);
    this.hpvRollenAuswahl.push(CreateRolleDTO.RolleEnum.HPVPRAXISSACHBEARBEITER);

    this.benutzerHpvRollen = this.getBenutzerHPVRollen(this.benutzer.rollen);
  }

  private getBenutzerHPVRollen(benutzerRollen: Array<BenutzerRolleDTO>): Array<BenutzerRolleDTO> {
    return benutzerRollen.filter(br => br.betrieb != undefined && br.rolle.includes("HPV_"));
  }

  bestaetigeLoeschen(rolle: BenutzerRolleDTO) {
    var betriebRolle: BetriebRolle = {betriebId: rolle.betrieb.id, betriebName: rolle.betrieb.name1, rolle: rolle.rolle, isUpdate: true};
    this.onDeleteRolle.emit([betriebRolle]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const updatedBenutzer: SimpleChange = changes.benutzer;
    if (updatedBenutzer) {
      this.benutzerHpvRollen = this.getBenutzerHPVRollen(updatedBenutzer.currentValue.rollen);
    }
  }

  addRolle($eventElement: BetriebDTO) {
    let createRolle: CreateRolleDTO = {benutzerId: this.benutzer.id, betriebId: $eventElement.id, rolle: this.selectedRolle, gln: this.gln};
    this.benutzerService.addRolle(createRolle).subscribe(benutzerDTO => {
      this.benutzer = benutzerDTO;
      this.benutzerHpvRollen = this.getBenutzerHPVRollen(this.benutzer.rollen);
      this.notificationService.handleSuccess("erfolg.benutzer.rolle.speichern");
    }, error => this.notificationService.handleErrorShort(error.error));
  }

  onSelectedRolleChange() {
    this.gln = undefined;
  }
}
