import {Component, OnInit} from '@angular/core';
import {BenutzerDTO, BenutzerRolleDTO, BenutzerService, BetriebeService, DeleteRolleDTO} from "../../core/api";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";
import {ConfirmDialogService} from "../../core/confirm-dialog/confirm-dialog.service";
import {NotificationService} from "../../core/notification/notification.service";
import {ApplikationEnum} from "../../core/api/model/applikationEnum";
import {ApplicationService} from "../../common/application.service";

@Component({
  selector: 'app-rollen-verwalten',
  templateUrl: './rollen-verwalten.component.html',
  styleUrls: ['./rollen-verwalten.component.scss']
})
export class RollenVerwaltenComponent implements OnInit {

  public benutzer: BenutzerDTO;
  public bearbeitenderBenutzer: BenutzerDTO;

  public applikationenEnum = ApplikationEnum;
  public anwendungenAuswahl: Array<String> = new Array<String>();
  public selectedAnwendung: String;

  public blockInput: boolean;

  constructor(public translateService: TranslateService, private applikationService: ApplicationService, private benutzerService: BenutzerService, private route: ActivatedRoute,
              private betriebService: BetriebeService, private confirmDialogService: ConfirmDialogService, private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe((params) => {
        let id = params['id'];
        this.benutzerService.getBenutzer(id).subscribe(
          success => this.benutzer = success,
          error => this.notificationService.handleErrorShort(error.error)
        );
      });
    this.bearbeitenderBenutzer = this.route.snapshot.data['currentUser'];
    this.initAnwendungenAuswahl();
  }

  private initAnwendungenAuswahl() {
    this.anwendungenAuswahl = Object.values(ApplikationEnum);
    if (!this.canManagePortalRoles() && this.applikationService.isApplicationActive(ApplikationEnum.GEFPORTAL)) {
      this.anwendungenAuswahl = this.anwendungenAuswahl.filter(anwendung => anwendung != ApplikationEnum.GEFPORTAL);
    }
    if (!this.canManageEfoRoles()) {
      this.anwendungenAuswahl = this.anwendungenAuswahl.filter(anwendung => anwendung != ApplikationEnum.EIGENEFORMELONLINE);
    }
    if (!this.canManageHPVRoles()) {
      this.anwendungenAuswahl = this.anwendungenAuswahl.filter(anwendung => anwendung != ApplikationEnum.HPV);
    }
  }

  private canManagePortalRoles(): boolean {
    return this.bearbeitenderBenutzer.rollen.filter(
      r => r.rolle === BenutzerRolleDTO.RolleEnum.ADMINISTRATOR
    ).length > 0;
  }

  private canManageEfoRoles(): boolean {
    return this.applikationService.isApplicationActive(ApplikationEnum.EIGENEFORMELONLINE) &&
      (this.hasBenutzerRolle(BenutzerRolleDTO.RolleEnum.ADMINISTRATOR) || this.hasBenutzerRolle(BenutzerRolleDTO.RolleEnum.EFOADMIN) || this.hasBenutzerRolle(BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER));
  }

  private canManageHPVRoles(): boolean {
    return this.applikationService.isApplicationActive(ApplikationEnum.HPV) &&
      (this.hasBenutzerRolle(BenutzerRolleDTO.RolleEnum.ADMINISTRATOR) || this.hasBenutzerRolle(BenutzerRolleDTO.RolleEnum.HPVADMIN) || this.hasBenutzerRolle(BenutzerRolleDTO.RolleEnum.HPVPRAXISADMINISTRATION) || this.hasBenutzerRolle(BenutzerRolleDTO.RolleEnum.HPVPRAXISLEITUNG));
  }

  private hasBenutzerRolle(rolle: BenutzerRolleDTO.RolleEnum): boolean {
    return this.bearbeitenderBenutzer.rollen.map(rolle => rolle.rolle).includes(rolle);
  }

  bestaetigeLoeschen(rolle: any) {
    if (rolle.betriebName != undefined) {
      if (rolle.isUpdate) {
        this.confirmDeletion("berechtigung.verwalten.bestaetigung.loeschen.rolle.betrieb", {betrieb: rolle.betriebName}, rolle);
      }
    } else {
      let translatedRolle = this.translateService.instant("benutzer.rolle." + rolle.rolle);
      this.confirmDeletion("berechtigung.verwalten.bestaetigung.loeschen", {rolle: translatedRolle}, rolle);
    }
  }

  private confirmDeletion(translationKey: string, param: any, rolle: any) {
    this.blockInput = true;
    this.confirmDialogService.confirmThis(this.translateService.instant(translationKey, param),
      () => {
        this.deleteRolle(rolle)
      },
      function () {
      });
  }

  private deleteRolle(rolle: any) {
    let deleteRolle: DeleteRolleDTO = {
      benutzerId: this.benutzer.id,
      betriebId: rolle.betriebId != undefined ? rolle.betriebId : null,
      rolle: rolle.rolle
    };
    this.blockInput = true;
    this.benutzerService.deleteRolle(deleteRolle).subscribe((benutzer) => {
      this.benutzer = benutzer;
      this.blockInput = false;
      this.notificationService.handleSuccess("erfolg.benutzer.rolle.loeschen");
    }, error => {
      this.blockInput = false;
      this.notificationService.handleErrorShort(error.error);
    });
  }
}
