import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApplikationSelectionComponent} from './applikation-selection/applikation-selection.component';
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule} from "@angular/forms";
import {LoginEventListenerService} from "./login-event-listener.service";

@NgModule({
  declarations: [ApplikationSelectionComponent],
  exports: [
    ApplikationSelectionComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule
  ]
})
export class CommonComponentsModule {
  constructor(private loginInterceptorService: LoginEventListenerService) {
  }
}
