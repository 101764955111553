<h1>{{ 'home.title' | translate}}</h1>

<div class="row">
  <div class='col image-teaser-s col-md-6' *ngIf="authService.canViewEfoKachel() | async" (click)="navigateToEFo()">
    <span class='teaser-label' translate="kachel.efo.titel"></span>
    <p class='teaser-small' translate="kachel.efo.beschreibung"></p>
  </div>
  <div class='col image-teaser-s-2 col-md-6' *ngIf="(authService.canViewHPVKachel() | async) && isHpvActive()" (click)="navigateToHPV()">
    <span class='teaser-label' translate="kachel.hpv.titel"></span>
    <p class='teaser-small' translate="kachel.hpv.beschreibung"></p>
  </div>
  <div class='col image-teaser-sirona col-md-6' *ngIf="authService.isLoggedIn() && isSironaActive()" (click)="navigateToSirona()">
    <span class='teaser-label' translate="kachel.sirona.titel"></span>
    <p class='teaser-small' translate="kachel.sirona.beschreibung"></p>
  </div>
</div>

<div class="row">
  <div class='col image-teaser-anmeldung-be-login col-md-12' *ngIf="!authService.isLoggedIn()" (click)="loginBeLogin()">
    <span class='teaser-label' translate="kachel.belogin.titel"></span>
    <p class='teaser-small' translate="kachel.belogin.beschreibung"></p>
  </div>
  <div class='col image-teaser-kita col-md-6' *ngIf="authService.isLoggedIn() && isKitaActive()" (click)="navigateToKita()">
    <span class='teaser-label' translate="kachel.kita.titel"></span>
    <p class='teaser-small' translate="kachel.kita.beschreibung"></p>
  </div>
  <div class='col image-teaser-anmeldung col-md-12' *ngIf="!authService.isLoggedIn()" (click)="authService.login()" >
    <span class='teaser-label' translate="kachel.anmeldung.titel"></span>
    <p class='teaser-small' translate="kachel.anmeldung.beschreibung"></p>
  </div>
  <div class='col image-teaser-berechtigung col-md-12' *ngIf="authService.isLoggedIn()" (click)="navigate('/berechtigung/beantragen')">
    <span class='teaser-label' translate="kachel.berechtigung.beantragen.titel"></span>
    <p class='teaser-small' translate="kachel.berechtigung.beantragen.beschreibung"></p>
  </div>
  <div class='col image-teaser-avg col-md-12' *ngIf="authService.isLoggedIn()  && isAvgActive()" (click)="navigateToAvg()">
    <span class='teaser-label' translate="kachel.avg.titel"></span>
    <p class='teaser-small' translate="kachel.avg.beschreibung"></p>
  </div>
  <div class='col image-teaser-aewb col-md-12' *ngIf="authService.isLoggedIn() && isAeWBActive()" (click)="navigateToAeWB()">
    <span class='teaser-label' translate="kachel.aewb.titel"></span>
    <p class='teaser-small' translate="kachel.aewb.beschreibung"></p>
  </div>
</div>
