<h1>{{ 'berechtigung.verwalten.titel' | translate }}</h1>
<div *ngIf="benutzer && bearbeitenderBenutzer">
  <form>
    <h2>{{benutzer?.email}}</h2>
    <div class='hr'></div>

    <div class="w100 form-group">
      <label for="anwendung" [ngClass]="translateService.currentLang=='de' ? 'label': 'label label-french'"
             translate="berechtigung.verwalten.label.anwendung"></label>
      <select
        id="anwendung"
        name="anwendung"
        class="form-control"
        [ngClass]="translateService.currentLang=='de' ? 'input': 'input input-french'"
        [(ngModel)]="selectedAnwendung"
        required>
        <option></option>
        <option *ngFor="let anwendung of anwendungenAuswahl"
                [ngValue]="anwendung">{{ 'anwendung.' + anwendung | translate }}</option>
      </select>
    </div>
  </form>

  <app-confirm-dialog></app-confirm-dialog>

  <!-- Portal -->
  <div *ngIf="applikationenEnum.GEFPORTAL == selectedAnwendung">
    <app-rollen-verwalten-portal (onDeleteRolle)="bestaetigeLoeschen($event[0])"
                                 [bearbeitenderBenutzer]="bearbeitenderBenutzer"
                                 [benutzer]="benutzer"
                                 [blockInput]="blockInput"></app-rollen-verwalten-portal>
  </div>

  <!-- EFo -->
  <div *ngIf="applikationenEnum.EIGENEFORMELONLINE == selectedAnwendung && canManageEfoRoles()">
    <app-rollen-verwalten-efo (onDeleteRolle)="bestaetigeLoeschen($event[0])" [benutzer]="benutzer" [blockInput]="blockInput" [bearbeitenderBenutzer]="bearbeitenderBenutzer"></app-rollen-verwalten-efo>
  </div>

  <!-- HPV. Todo: https://jira.bedag.ch/browse/GSIHPV-23 -->
  <div *ngIf="applikationenEnum.HPV == selectedAnwendung && canManageHPVRoles()">
    <app-rollen-verwalten-hpv [blockInput]="blockInput" [benutzer]="benutzer" (onDeleteRolle)="bestaetigeLoeschen($event[0])" [bearbeitenderBenutzer]="bearbeitenderBenutzer"></app-rollen-verwalten-hpv>
  </div>

  <br>
  <button class="btn btn-primary" style="float: left;" routerLink="/benutzer/detail/{{benutzer.id}}">
    <i class="fa fa-arrow-left" aria-hidden="true"></i>
    <span translate="berechtigung.verwalten.button.zurueck"></span>
  </button>

</div>
