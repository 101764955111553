import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-fullscreen-spinner',
  templateUrl: './full-screen-spinner.component.html',
  styleUrls: ['./full-screen-spinner.component.scss']
})
export class FullScreenSpinnerComponent {
  // For so many milliseconds the spinner will be transparent after "working" has been set to true. Used to avoid flickering
  @Input()
  public transparentForDurationMs = 0;

  transparent = false;

  private _show = false;
  private timeoutId: any;

  @Input()
  public set show(show: boolean) {
    if (!this._show && show) {
      this.displaySpinner();
    } else if (this._show && !show) {
      this.resetTimeout();
    }

    this._show = show;
  }

  public get show() {
    return this._show;
  }

  private displaySpinner() {
    if (this.transparentForDurationMs > 0) {
      this.transparent = true;
      const that = this;
      // set to non-transparent after the configured duration
      this.timeoutId = setTimeout(() => {
        that.transparent = false;
      }, this.transparentForDurationMs);
    } else {
      this.transparent = false;
    }
  }

  private resetTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = undefined;
    }
  }
}
