import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BerechtigungBeantragenComponent} from "./berechtigung-beantragen/berechtigung-beantragen.component";
import {RollenVerwaltenComponent} from "./rollen-verwalten/rollen-verwalten.component";
import {TranslateModule} from "@ngx-translate/core";
import {ConfirmDialogModule} from "../core/confirm-dialog/confirm-dialog.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule, Routes} from "@angular/router";
import {Ng2OrderModule} from "ng2-order-pipe";
import {BerechtigungBeantragenEfoComponent} from './berechtigung-beantragen-efo/berechtigung-beantragen-efo.component';
import {BerechtigungBeantragenBetriebeSearchComponent} from './berechtigung-beantragen-betriebe-search/berechtigung-beantragen-betriebe-search.component';
import { BerechtigungBeantragenHpvComponent } from './berechtigung-beantragen-hpv/berechtigung-beantragen-hpv.component';
import {NgbPopoverModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import { RollenVerwaltenPortalComponent } from './rollen-verwalten-portal/rollen-verwalten-portal.component';
import { RollenVerwaltenHpvComponent } from './rollen-verwalten-hpv/rollen-verwalten-hpv.component';
import { RollenVerwaltenEfoComponent } from './rollen-verwalten-efo/rollen-verwalten-efo.component';
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {NgxPaginationModule} from "ngx-pagination";
import { RollenVerwaltenBetriebeSearchComponent } from './rollen-verwalten-betriebe-search/rollen-verwalten-betriebe-search.component';
import {CurrentUserResolver} from "../core/resolvers/current-user.resolver";
import { BerechtigungBeantragenSelectRoleComponent } from './berechtigung-beantragen-select-role/berechtigung-beantragen-select-role.component';
import {CoreModule} from "../core/core.module";


const routes: Routes = [
  {path: 'berechtigung/beantragen', component: BerechtigungBeantragenComponent},
  {path: 'berechtigung/beantragen/efo', component: BerechtigungBeantragenEfoComponent},
  {path: 'berechtigung/beantragen/hpv', component: BerechtigungBeantragenHpvComponent},
  {path: 'berechtigung/verwalten/:id', component: RollenVerwaltenComponent, resolve: {currentUser: CurrentUserResolver}}
];

@NgModule({
  declarations: [
    BerechtigungBeantragenComponent,
    RollenVerwaltenComponent,
    BerechtigungBeantragenEfoComponent,
    BerechtigungBeantragenBetriebeSearchComponent,
    BerechtigungBeantragenHpvComponent,
    RollenVerwaltenPortalComponent,
    RollenVerwaltenHpvComponent,
    RollenVerwaltenEfoComponent,
    RollenVerwaltenBetriebeSearchComponent,
    BerechtigungBeantragenSelectRoleComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule.forRoot(),
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    Ng2OrderModule,
    NgbTooltipModule,
    NgbPopoverModule,
    Ng2SearchPipeModule,
    NgxPaginationModule
  ],
  entryComponents: [
    BerechtigungBeantragenSelectRoleComponent
  ]
})
export class BerechtigungModule {
}
