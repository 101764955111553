<!-- Betriebesearch -->
<app-rollen-verwalten-betriebe-search [showSearchResults]="showSearchResults" (onSelectBetriebEvent)="fuegeBetriebHinzu($event[0])" [betriebRollenListe]="betriebRollenListe"
                                      [bearbeitenderBenutzer]="bearbeitenderBenutzer" applikation="EIGENE_FORMEL_ONLINE">
</app-rollen-verwalten-betriebe-search>
<br>
<!-- Current Benutzerrollen in EFo -->
<table>
  <thead>
  <tr>
    <th translate="berechtigung.verwalten.label.betrieb"></th>
    <th translate="berechtigung.verwalten.label.rolle"></th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngIf="betriebRollenListe.length == 0">
    <td></td>
    <td translate="berechtigung.verwalten.keine.berechtigungen"></td>
    <td></td>
  </tr>
  <tr *ngFor="let betriebRolle of getSortedBetriebRolle()">
    <td>{{betriebRolle?.betriebName}}</td>
    <td>
      <select
        id="betriebRolle"
        name="betriebRolle"
        class="form-control input w100"
        [(ngModel)]="betriebRolle.rolle">
        <option></option>
        <option *ngFor="let rolle of getRollenAuswahlenOptions(betriebRolle)" [ngValue]="rolle">{{'benutzer.rolle.' + rolle | translate}}</option>
      </select>
    </td>
    <td>
      <button
        class="btn btn-primary btn-sm"
        (click)="bestaetigeLoeschen(betriebRolle)" [disabled]="blockInput">
        <i class="far fa-trash-alt"></i>
      </button>
      <button
        class="btn btn-primary btn-sm"
        (click)="saveEfoRolle(betriebRolle)" [disabled]="blockInput">
        <i class="fas fa-save"></i></button>
    </td>
  </tr>
  </tbody>
</table>
