<h1 translate="benutzer.details.title"></h1>
<form *ngIf="benutzer && bearbeitenderBenutzer">
  <div class='inputfield-watermarked'>
    <input value='' class='watermarked' placeholder='{{benutzer?.vorname}} {{benutzer?.name}}' disabled>
    <span class='floating-label open'>{{ 'benutzer.details.fullname' | translate }}</span>
  </div>
  <div class='inputfield-watermarked'>
    <input value='' class='watermarked' placeholder='{{ benutzer?.benutzerName }}' disabled>
    <span class='floating-label open'>{{ 'benutzer.details.username' | translate }}</span>
  </div>
  <div class='inputfield-watermarked'>
    <input value='' class='watermarked' placeholder='{{benutzer?.email}}' disabled>
    <span class='floating-label open'>{{ 'benutzer.details.email' | translate }}</span>
  </div>
  <div class='inputfield-watermarked'>
    <textarea type="textarea" class="watermarked" placeholder='{{ getFormattedRollen(benutzer?.rollen)}}' rows="5" cols="1" disabled></textarea>
    <span class='floating-label open'>{{ 'benutzer.details.rollen' | translate }}</span>
    &nbsp;
    <button style="float: right" (click)="editRollen()" class="btn btn-primary btn-sm" title="{{ 'benutzer.details.tooltip' | translate }}" *ngIf="showBerechtigungVerwalten()">
      <i class="fas fa-pencil-alt" aria-hidden="true"></i>
    </button>
  </div>
</form>

<button class="btn btn-primary" style="float: left;" routerLink="/benutzer">
  <i class="fa fa-arrow-left" aria-hidden="true"></i>
  <span translate="berechtigung.verwalten.button.zurueck"></span>
</button>
