<form [hidden]="!benutzerApplikationen || benutzerApplikationen.length == 1">
  <div class="inline-block w20" style="display: inline-block;">
    <label class="lbl-applikation">
      {{ 'global.applikation' | translate }}
    </label>
  </div>
  <div class="inline-block w60 mb10" style="display: inline-block;">
    <select class="form-control" style="width: 50%;" [ngModel]="selectedApplikation" (ngModelChange)="onApplikationChanged($event)" name="applikationSelect">
      <option *ngFor="let applikation of benutzerApplikationen" [value]="applikation">{{ 'anwendung.' + applikation | translate }}</option>
    </select>
  </div>
  <hr>
</form>
