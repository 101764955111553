import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {BenutzerDTO, BenutzerRolleDTO, BenutzerService} from "../../core/api";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../core/notification/notification.service";

@Component({
  selector: 'app-benutzer-detail',
  templateUrl: './benutzer-detail.component.html',
  styleUrls: ['./benutzer-detail.component.scss']
})
export class BenutzerDetailComponent implements OnInit {

  public benutzer: BenutzerDTO;
  public bearbeitenderBenutzer: BenutzerDTO;

  constructor(private route: ActivatedRoute, private router: Router, public translateService: TranslateService, private notificationService: NotificationService,
              private benutzerService: BenutzerService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe((params) => {
        let id = params['id'];
        this.benutzerService.getBenutzer(id).subscribe(
          success => this.benutzer = success,
          error => this.notificationService.handleErrorShort(error.error)
        );
      });
    this.benutzerService.self().subscribe(
      benutzerDTO => {
        this.bearbeitenderBenutzer = benutzerDTO;
      }, error => this.notificationService.handleErrorShort("error.benutzer.self")
    );
  }

  showBerechtigungVerwalten() {
    return this.bearbeitenderBenutzer.rollen.filter(
      benutzerRolle => benutzerRolle.rolle.toString().includes("ADMIN") || benutzerRolle.rolle == BenutzerRolleDTO.RolleEnum.HPVPRAXISLEITUNG
      || benutzerRolle.rolle == BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER
    ).length;
  }

  getFormattedRollen(rollen: Array<BenutzerRolleDTO>) {
    if (rollen == undefined || rollen.length == 0) {
      return "-";
    }
    return rollen.map(rolle => this.createRolleString(rolle)).join(', ');
  }

  private createRolleString(rolle: BenutzerRolleDTO): String {
    return this.translateService.instant("benutzer.rolle." + rolle.rolle) + (rolle.betrieb != null ? " (" + rolle.betrieb.name1 + ")" : "");
  }

  editRollen() {
    this.router.navigate(['/berechtigung/verwalten', this.benutzer.id]);
  }
}

