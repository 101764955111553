import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {BenutzerDTO, BenutzerRolleDTO, BenutzerService} from "../../core/api";
import {Router} from "@angular/router";
import {ConfirmDialogService} from "../../core/confirm-dialog/confirm-dialog.service";
import {NotificationService} from "../../core/notification/notification.service";
import {TranslateService} from "@ngx-translate/core";
import {ApplikationEnum} from "../../core/api/model/applikationEnum";
import {AuthenticationService} from "../../auth/auth.service";

@Component({
  selector: 'app-benutzer-list',
  templateUrl: './benutzer-list.component.html',
  styleUrls: ['./benutzer-list.component.scss']
})
export class BenutzerListComponent implements OnInit {

  benutzer$: Observable<BenutzerDTO[]>;
  filter: String;
  key: string = ''; // default sorting column
  reverse: boolean = false;
  page: number = 1;
  itemsPerPage: number = 10;
  bearbeitenderBenutzer: BenutzerDTO;

  constructor(private benutzerService: BenutzerService, private router: Router, private confirmDialogService: ConfirmDialogService,
              public authService: AuthenticationService, private translateService: TranslateService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.benutzerService.self().subscribe(
      benutzerDTO => {
        this.bearbeitenderBenutzer = benutzerDTO;
      },
      error => this.notificationService.handleErrorShort("error.benutzer.self")
    );
  }

  private deleteBenutzer(benutzer: BenutzerDTO) {
    this.benutzerService.deleteBenutzer(benutzer.id.toString()).subscribe(
      success => {
        this.notificationService.handleSuccess("erfolg.benutzer.loeschen", {benutzer: benutzer.benutzerName});
      },
      error => this.notificationService.handleErrorShort(error.error)
    );
  }

  showDialog(currentBenutzer: BenutzerDTO) {
    var name = currentBenutzer.vorname + " " + currentBenutzer.name;
    this.confirmDialogService.confirmThis(this.translateService.instant("benutzer.bestaetigung.loeschen", {benutzer: name}),
      () => this.deleteBenutzer(currentBenutzer)
      , function () {
      })
  }

  showDetails(benutzer: BenutzerDTO) {
    this.router.navigate(['/benutzer/detail', benutzer.id]);
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  isBearbeitenderBenutzerAdminImPortal() {
    if (this.bearbeitenderBenutzer == undefined) {
      return false;
    }
    let adminRollen: Array<any> = this.bearbeitenderBenutzer.rollen.filter(
      r => r.rolle === BenutzerRolleDTO.RolleEnum.ADMINISTRATOR
    );
    return adminRollen.length > 0;
  }

  onApplikationChanged(applikation: ApplikationEnum) {
    this.benutzer$ = this.benutzerService.getBenutzerListe(applikation);
  }
}
