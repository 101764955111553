<div style="margin-top: 25px;" *ngIf="showSearchFrom()">
  <h3 translate="berechtigung.verwalten.unter.titel"></h3>
  <form #searchBetriebForm="ngForm" (ngSubmit)="searchBetrieb()">
    <div class="w100 form-group">
      <label for="gln" [ngClass]="translateService.currentLang=='de' ? 'label': 'label label-french'"
             translate="berechtigung.verwalten.label.gln"></label>
      <input
        id="gln"
        name="gln"
        class="form-control"
        [ngClass]="translateService.currentLang=='de' ? 'input': 'input input-french'"
        [(ngModel)]="searchBetriebDTO.gln">
      <label for="name" [ngClass]="translateService.currentLang=='de' ? 'label-2': 'label-2 label-french'"
             translate="berechtigung.verwalten.label.name"></label>
      <input
        id="name"
        name="name"
        class="form-control"
        [ngClass]="translateService.currentLang=='de' ? 'input': 'input input-french'"
        [(ngModel)]="searchBetriebDTO.name1">
    </div>
    <div class="w100 form-group">
      <label for="ort" [ngClass]="translateService.currentLang=='de' ? 'label': 'label label-french'"
             translate="berechtigung.verwalten.label.ort"></label>
      <input
        id="ort"
        name="ort"
        class="form-control"
        [ngClass]="translateService.currentLang=='de' ? 'input': 'input input-french'"
        [(ngModel)]="searchBetriebDTO.ort">
      <input type="submit" class="btn btn-primary" [ngClass]="translateService.currentLang=='de' ? 'button': 'button button-french'"
             value="{{'berechtigung.verwalten.button.suche' | translate }}">
    </div>
  </form>
  <br>
</div>

<div *ngIf="showSearchResults">
  <table class="table">
    <thead>
    <tr>
      <th translate="berechtigung.verwalten.label.gln"></th>
      <th translate="berechtigung.verwalten.label.name"></th>
      <th translate="berechtigung.verwalten.label.strasse"></th>
      <th translate="berechtigung.verwalten.label.ort"></th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="betriebeAuswahl.length == 0">
      <td></td>
      <td translate="berechtigung.verwalten.keine.suchresultate"></td>
      <td></td>
      <td></td>
    </tr>
    <ng-container
      *ngFor="let betrieb of getSortedBetriebe() | orderBy: key : reverse | filter: filter | paginate: {id:'betriebeAuswahl', itemsPerPage: itemsPerPage, currentPage: page }">
    <tr>
        <td>{{betrieb?.gln}}</td>
        <td>{{betrieb?.name1}}</td>
        <td>{{betrieb?.strasse}}</td>
        <td>{{betrieb?.ort}}</td>
        <td>
          <button
            [hidden]="hasBetriebAlreadyBeenAdded(betrieb)"
            class="btn btn-primary btn-sm"
            (click)="onSelectBetrieb(betrieb)"
          ><i class="fas fa-plus"></i></button>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <pagination-controls (pageChange)="page = $event" previousLabel="" id="betriebeAuswahl" class="pagination-control"
                       nextLabel=""></pagination-controls>
</div>
