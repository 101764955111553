import { ApplikationEnum } from "../core/api/model/applikationEnum";
import { environment } from "../../environments/environment";
import { KachelEnum } from "../core/api/model/kachelEnum";
import * as i0 from "@angular/core";
export class ApplicationService {
    constructor() {
        let activeApplicationStringArray = environment.activeApplications;
        if (activeApplicationStringArray) {
            this.activeApplications = activeApplicationStringArray.map(a => ApplikationEnum[a]);
        }
        else {
            this.activeApplications = Object.values(ApplikationEnum).map(ae => ae);
        }
        let activeKachelnStringArray = environment.activeKacheln;
        if (activeKachelnStringArray) {
            this.activeKacheln = activeKachelnStringArray.map(k => KachelEnum[k]);
        }
        else {
            this.activeKacheln = Object.values(KachelEnum).map(ke => ke);
        }
    }
    isApplicationActive(applikationEnum) {
        return this.activeApplications.includes(applikationEnum);
    }
    isKachelActive(kachelEnum) {
        return this.activeKacheln.includes(kachelEnum);
    }
}
ApplicationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicationService_Factory() { return new ApplicationService(); }, token: ApplicationService, providedIn: "root" });
