<div class='footer-kbe'>
  <img class='logo-kbe-footer' src='../../assets/img/Kanton-Bern.svg'>
  <p class='copyright cr-kbe'>{{ 'footer.copyright' | translate:{"year": getCurrentYear()} }}</p>
  <div class='label-footer'>{{ 'footer.follow' | translate }}</div>
  <div class='sm-icons'>
    <a href='https://www.facebook.com/gsi.dssi.ktbern' class='fb-ico' target="_blank"></a>
    <a href='https://twitter.com/GSI_DSSI_BE' class='tw-ico' target="_blank"></a>
  </div>
  <div class='footer-nav'>
    <a [href]="getGSIKantonBernKontaktUrl()" class='footer-menue f1' target="_blank">{{ 'footer.kontakt' | translate }}</a>
    <a [href]="getGSIKantonBernRechtlichesUrl()" class='footer-menue f2' target="_blank">{{ 'footer.datenschutz' | translate }}</a>
    <a [href]="getGSIKantonBernImpressumUrl()" class='footer-menue f3' target="_blank">{{ 'footer.impressum' | translate }}</a>
  </div>
  <div>
    <ul class="portal-version">
      <li *ngIf="portalBackendVersion != undefined">
        <span>{{ 'footer.label.version_backend' | translate }}</span>
        <span>{{getBackendVersion()}}</span>
      </li>
      <li>
        <span>{{ 'footer.label.version_frontend' | translate }}</span>
        <span>{{getFrontendVersion()}}</span>
      </li>
    </ul>
  </div>
</div>
