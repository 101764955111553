/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-screen-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./full-screen-spinner.component";
var styles_FullScreenSpinnerComponent = [i0.styles];
var RenderType_FullScreenSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FullScreenSpinnerComponent, data: {} });
export { RenderType_FullScreenSpinnerComponent as RenderType_FullScreenSpinnerComponent };
function View_FullScreenSpinnerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "fa-3x m-auto fas fa-spinner fa-spin fa-fw"]], null, null, null, null, null))], null, null); }
function View_FullScreenSpinnerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "modal-backdrop fade"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "show": 0 }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(8, 0, null, null, 7, "div", [["class", "modal fade show d-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "modal-dialog modal-dialog-centered"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullScreenSpinnerComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal-backdrop fade"; var currVal_1 = _ck(_v, 5, 0, !_co.transparent); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = !_co.transparent; _ck(_v, 13, 0, currVal_2); }, null); }
export function View_FullScreenSpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FullScreenSpinnerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FullScreenSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-fullscreen-spinner", [], null, null, null, View_FullScreenSpinnerComponent_0, RenderType_FullScreenSpinnerComponent)), i1.ɵdid(1, 49152, null, 0, i3.FullScreenSpinnerComponent, [], null, null)], null, null); }
var FullScreenSpinnerComponentNgFactory = i1.ɵccf("app-fullscreen-spinner", i3.FullScreenSpinnerComponent, View_FullScreenSpinnerComponent_Host_0, { transparentForDurationMs: "transparentForDurationMs", show: "show" }, {}, []);
export { FullScreenSpinnerComponentNgFactory as FullScreenSpinnerComponentNgFactory };
