<form *ngIf="benutzer">
  <h1 translate="berechtigung.beantragen.titel"></h1>
  <div class="inline-block w35">{{'berechtigung.beantragen.fuer' | translate}}</div>
  <div class="inline-block w60">
    <div class='inputfield-watermarked'>
      <input value='' class='watermarked' placeholder='{{benutzer?.vorname}} {{benutzer?.name}} ({{ benutzer?.benutzerName }})' disabled>
      <span class='floating-label open'>{{ 'berechtigung.beantragen.name' | translate }}</span>
    </div>
  </div>
  <div class="inline-block w35 mb10">&nbsp;</div>
  <div class="inline-block w60 mb10">
    <div class='inputfield-watermarked'>
      <input value='' class='watermarked' placeholder='{{benutzer?.email}}' disabled>
      <span class='floating-label open'>{{ 'berechtigung.beantragen.email' | translate }}</span>
    </div>
  </div>
  <br>
  <div>
    <div class="inline-block w35">
      <label for="anwendung" [ngClass]="translateService.currentLang=='de' ? 'label': 'label label-french'"
             translate="berechtigung.beantragen.label.anwendung"></label>
    </div>
    <div class="inline-block w60 mb10">
      <select
        id="anwendung"
        name="anwendung"
        class="form-control"
        style="width: 50%;"
        [ngClass]="translateService.currentLang=='de' ? 'input': 'input input-french'"
        [(ngModel)]="selectedAnwendung"
        (ngModelChange)="onSelectedAnwendungChange()"
        [hidden]="anwendungen.length == 1"
        required>
        <option></option>
        <option *ngFor="let anwendung of anwendungen"
                [ngValue]="anwendung">{{ 'anwendung.' + anwendung | translate }}</option>
      </select>
      <div *ngIf="anwendungen.length == 1">
        <input type="text" value="{{ 'anwendung.' + anwendungen[0] | translate }}" disabled>
        <input type="button" value="{{ 'global.continue' | translate }}" (click)="onSelectedAnwendungChange()" class="btn btn-primary" style="width: 100px; margin-left: 10px;">
      </div>
    </div>
  </div>
  <div class='hr'></div>
</form>

<br><br>
<button class="btn btn-primary" style="float: left; display: inline;" (click)="navigateBack()">
  <i class="fa fa-arrow-left" aria-hidden="true"></i>
  <span translate="berechtigung.beantragen.button.zurueck"></span>
</button>
