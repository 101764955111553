import { OAuthService } from "angular-oauth2-oidc";
import { AuthenticationService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "../auth/auth.service";
import * as i2 from "angular-oauth2-oidc";
import * as i3 from "@angular/router";
/**
 * Service listening for Login / Logout - Events from OAuthService.
 *
 * For a SSO-Login with Identity-Provider 'BE-Login' we need to redirect the browser to a specific Logout-URL in order to invalidate the user-session after the SSO-Logout.
 * Unfortunately we have to do this workaround, since at the moment the SAML-Implementation for BE-Login does not support a Logout-functionality.
 *
 * */
export class LoginEventListenerService {
    constructor(authService, oauthService, router) {
        this.authService = authService;
        this.oauthService = oauthService;
        this.router = router;
        this.isBeLogin = false;
        this.init();
    }
    init() {
        //subscribe to login-event, so we can distinguish if user logged in with be-login or otherwise
        this.oauthService.events.subscribe(e => {
            if (e.type === 'token_received') {
                this.authService.userLoggedIn$.subscribe((isLoggedIn) => {
                    if (isLoggedIn) {
                        this.handleIdentityClaim();
                    }
                });
            }
            else if (e.type === 'logout') {
                if (this.isBeLogin) {
                    localStorage.setItem("clearBeLoginSession", "true");
                    this.isBeLogin = false;
                }
            }
            else if (e.type === 'discovery_document_loaded') {
                /* If we already have identity-claims, e.g. after a refresh,
                /* we can use them for finding out if we are logged in via BE-Login */
                if (this.oauthService.getIdentityClaims()) {
                    this.handleIdentityClaim();
                }
                if (localStorage.getItem("clearBeLoginSession") && !this.authService.isLoggedIn()) {
                    this.isBeLogin = false;
                    localStorage.removeItem("clearBeLoginSession");
                    window.location.href = environment.beLoginLogoutUrl;
                }
            }
        });
    }
    /* Keycloak is mapped to return a userinfo-attribute 'idpname' if user is logged in
    /* via be-login (value 'be-login') or adfs (value 'adfs'). Login with a keycloak-user does not return this attribute */
    handleIdentityClaim() {
        let identityClaims = this.oauthService.getIdentityClaims();
        if (identityClaims && identityClaims.hasOwnProperty("idpname")) {
            let ipd = identityClaims['idpname'];
            if (ipd == 'be-login') {
                this.isBeLogin = true;
            }
        }
        else {
            this.isBeLogin = false;
        }
    }
}
LoginEventListenerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginEventListenerService_Factory() { return new LoginEventListenerService(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.OAuthService), i0.ɵɵinject(i3.Router)); }, token: LoginEventListenerService, providedIn: "root" });
