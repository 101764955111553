import { BenutzerRolleDTO, BenutzerService } from "../core/api";
import { LoginService } from "bedag-angular-components";
import { OAuthService } from "angular-oauth2-oidc";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { Observable, of } from "rxjs";
import { NotificationService } from "../core/notification/notification.service";
import * as i0 from "@angular/core";
import * as i1 from "angular-oauth2-oidc";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../core/api/api/benutzer.service";
import * as i5 from "../core/notification/notification.service";
export class AuthenticationService extends LoginService {
    constructor(oauth, router, location, benutzerService, oauthService, notificationService) {
        super(oauth, router, location);
        this.benutzerService = benutzerService;
        this.oauthService = oauthService;
        this.notificationService = notificationService;
        this.loading = false;
        this.EFO_ADMIN_AND_BETRIEBSLEITER = [BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER, BenutzerRolleDTO.RolleEnum.EFOADMIN];
        this.HPV_ADMININSTRATION_AND_PRAXISLEITUNG = [BenutzerRolleDTO.RolleEnum.HPVADMIN, BenutzerRolleDTO.RolleEnum.HPVPRAXISADMINISTRATION, BenutzerRolleDTO.RolleEnum.HPVPRAXISLEITUNG];
        this.EFO_ROLLEN = [BenutzerRolleDTO.RolleEnum.EFOBETRIEBSMITARBEITER, BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER, BenutzerRolleDTO.RolleEnum.EFOADMIN];
        this.HPV_ROLLEN = [BenutzerRolleDTO.RolleEnum.HPVADMIN, BenutzerRolleDTO.RolleEnum.HPVPRAXISLEITUNG, BenutzerRolleDTO.RolleEnum.HPVPRAXISSACHBEARBEITER, BenutzerRolleDTO.RolleEnum.HPVPRAXISADMINISTRATION];
        this.SUPERUSER = [BenutzerRolleDTO.RolleEnum.ADMINISTRATOR];
        this.ADMINISTRATOR = [BenutzerRolleDTO.RolleEnum.ADMINISTRATOR, BenutzerRolleDTO.RolleEnum.EFOADMIN, BenutzerRolleDTO.RolleEnum.HPVADMIN];
        this.userLoggedIn$ = new Observable(subscriber => {
            subscriber.next(this.userLoggedIn);
            let previousState = this.userLoggedIn;
            setInterval(() => {
                if (previousState !== this.userLoggedIn) {
                    previousState = this.userLoggedIn;
                    subscriber.next(this.userLoggedIn);
                }
            }, 100);
        });
    }
    logout() {
        this.userDetails = null;
        this.benutzer = null;
        super.logout();
    }
    login(path) {
        return super.login(path).then((result) => {
            return result;
        });
    }
    isLoggedIn() {
        return this.userLoggedIn;
    }
    benutzerHasRolle(benutzerRollen, acceptedRoles) {
        return (benutzerRollen.filter(benutzerRolle => {
            return acceptedRoles.includes(benutzerRolle.rolle);
        }).length > 0);
    }
    canViewEfoKachel() {
        return this.hasAnyRolle(this.EFO_ROLLEN);
    }
    canViewHPVKachel() {
        return this.hasAnyRolle(this.HPV_ROLLEN);
    }
    isAdminOrLeitung() {
        return this.hasAnyRolle(this.EFO_ADMIN_AND_BETRIEBSLEITER.concat(this.HPV_ADMININSTRATION_AND_PRAXISLEITUNG));
    }
    isAdministrator() {
        return this.hasAnyRolle(this.ADMINISTRATOR);
    }
    hasAnyRolle(rollen) {
        if (!this.isLoggedIn()) {
            return of(false);
        }
        if (this.benutzer == null && !this.loading) {
            this.loading = true;
            this.benutzerService.self().subscribe((benutzer) => {
                this.benutzer = benutzer;
                this.loading = false;
                return false;
            }, error => this.notificationService.handleErrorShort("error.benutzer.self"));
        }
        if (this.benutzer != null) {
            if (this.benutzerHasRolle(this.benutzer.rollen, this.SUPERUSER.concat(rollen))) {
                return of(true);
            }
            return of(false);
        }
        return of(false);
    }
    relogin() {
        this.oauthService.initImplicitFlow();
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i4.BenutzerService), i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(i5.NotificationService)); }, token: AuthenticationService, providedIn: "root" });
