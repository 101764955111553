import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  constructor(private translateService: TranslateService, private titleService: Title) {}

  ngOnInit() {
    this.translateService.setDefaultLang((window as any).environment.defaultLanguage);

    let language = localStorage.getItem('language');
    if (language != null) {
      this.translateService.use(language);
    } else {
      this.translateService.use((window as any).environment.defaultLanguage);
    }

    // we bundle the translations to prevent a flash of untranslated content
    // (this does not significantly increase bundle size unless you have dozens of translations)
    for (const lang of (window as any).environment.availableLanguages) {
      this.translateService.setTranslation(lang, require(`../i18n/locale-${lang}.json`));
    }

    this.translateTitle();
    this.translateService.onLangChange.subscribe(() => {
      this.translateTitle();
    });
  }

  private translateTitle() {
    this.translateService.get('home.title').subscribe((translatedTitle: string) => {
      this.titleService.setTitle(translatedTitle);
    });
  }
}
