import { AlertHolderService } from "./alert-holder.service";
import { TranslateService } from "@ngx-translate/core";
import * as i0 from "@angular/core";
import * as i1 from "./alert-holder.service";
import * as i2 from "@ngx-translate/core";
export class NotificationService {
    constructor(alertHolderService, translateService) {
        this.alertService = alertHolderService;
        this.translateService = translateService;
    }
    handleErrorExtensive(messageKey, error, interpolateParams) {
        this.alertService.danger(this.translateService.instant(messageKey, interpolateParams), error);
    }
    handleErrorShort(messageKey, interpolateParams) {
        this.alertService.danger(this.translateService.instant(messageKey, interpolateParams), null);
    }
    handleSuccess(messageKey, interpolateParams) {
        this.alertService.success(this.translateService.instant(messageKey, interpolateParams));
    }
    addErrorMessage(messageKey, interpolateParams) {
        let message = this.translateService.instant(messageKey, interpolateParams);
        this.alertService.danger(message, null);
    }
    addWarnMessage(messageKey, interpolateParams) {
        let message = this.translateService.instant(messageKey, interpolateParams);
        this.alertService.danger(message, null);
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.AlertHolderService), i0.ɵɵinject(i2.TranslateService)); }, token: NotificationService, providedIn: "root" });
