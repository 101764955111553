import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BenutzerDTO, BenutzerService, BetriebDTO, BetriebeService} from "../../core/api";
import {Observable} from "rxjs";
import {Location} from "@angular/common";
import {NotificationService} from "../../core/notification/notification.service";

@Component({
  selector: 'app-betriebe-detail',
  templateUrl: './betriebe-detail.component.html',
  styleUrls: ['./betriebe-detail.component.scss']
})
export class BetriebeDetailComponent implements OnInit {

  filter: String;
  key: string = ''; // default sorting column
  reverse: boolean = false;
  page: number = 1;
  itemsPerPage: number = 10;

  benutzers$: Observable<BenutzerDTO[]>;
  betrieb: BetriebDTO;

  constructor(private route: ActivatedRoute, private location: Location, private betriebService: BetriebeService,
              private benutzerService: BenutzerService, private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.route.params
      .subscribe((params) => {
        let id = params['id'];
        this.betriebService.getBetriebById(id).subscribe(
          resp => {
            this.betrieb = resp;
            this.benutzers$ = this.benutzerService.getBenutzerByBetrieb(this.betrieb.id.toString());
          }, error => this.notificationService.handleErrorShort(error.error));
      });
  }

  goBack() {
    this.location.back();
  }
}
