import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BenutzerDTO, BenutzerService, BetriebDTO, BetriebeService, SearchBetriebDTO} from "../../core/api";
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "../../core/notification/notification.service";
import ApplikationEnum = SearchBetriebDTO.ApplikationEnum;

@Component({
  selector: 'app-berechtigung-beantragen-betriebe-search',
  templateUrl: './berechtigung-beantragen-betriebe-search.component.html',
  styleUrls: ['./berechtigung-beantragen-betriebe-search.component.scss']
})
export class BerechtigungBeantragenBetriebeSearchComponent implements OnInit {

  @Input() showGlnSearch: boolean = true;
  @Input() applikation: ApplikationEnum;
  @Output() onBeantrageBerechtigung: EventEmitter<any> = new EventEmitter();

  public benutzer: BenutzerDTO;
  public betriebe: Array<BetriebDTO>;
  public searchBetriebDTO: SearchBetriebDTO = {applikation: this.applikation};
  public key: string = ''; // default sorting column
  public reverse: boolean = false;
  filter: String;
  page: number = 1;
  itemsPerPage: number = 15;

  constructor(public translateService: TranslateService, private benutzerService: BenutzerService,
              private betriebService: BetriebeService, private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.benutzerService.self().subscribe(
      benutzerDTO => this.benutzer = benutzerDTO,
      error => this.notificationService.handleErrorShort("error.benutzer.self")
    );
    this.initSearchBetriebDTO();
  }

  initSearchBetriebDTO() {
    this.searchBetriebDTO.applikation = this.applikation;
    this.searchBetriebDTO.ort = null;
    this.searchBetriebDTO.name1 = null;
    this.searchBetriebDTO.gln = null;
    this.searchBetriebDTO.strasse = null;
  }

  searchBetrieb() {
    this.setAllInputFieldsWithEmptyStringNull();
    this.betriebService.searchBetrieb(this.searchBetriebDTO).subscribe(
      success => this.betriebe = success,
      error => this.notificationService.handleErrorExtensive("error.betrieb.search", error)
    )
  }

  setAllInputFieldsWithEmptyStringNull() {
    if (this.searchBetriebDTO.ort != null && this.searchBetriebDTO.ort.trim().length == 0) {
      this.searchBetriebDTO.ort = null;
    }
    if (this.searchBetriebDTO.name1 != null && this.searchBetriebDTO.name1.trim().length == 0) {
      this.searchBetriebDTO.name1 = null;
    }
    if (this.searchBetriebDTO.gln != null && this.searchBetriebDTO.gln.trim().length == 0) {
      this.searchBetriebDTO.gln = null;
    }
    if (this.searchBetriebDTO.strasse != null && this.searchBetriebDTO.strasse.trim().length == 0) {
      this.searchBetriebDTO.strasse = null;
    }
  }

  beantrageBerechtigungBetrieb(betrieb: BetriebDTO) {
    this.onBeantrageBerechtigung.emit([betrieb]);
  }

  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

}
