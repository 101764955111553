/**
 * GEF Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SearchBetriebDTO { 
    applikation: SearchBetriebDTO.ApplikationEnum;
    name1?: string;
    ort?: string;
    strasse?: string;
    gln?: string;
}
export namespace SearchBetriebDTO {
    export type ApplikationEnum = 'EIGENE_FORMEL_ONLINE' | 'GEF_PORTAL' | 'GSI_HPV';
    export const ApplikationEnum = {
        EIGENEFORMELONLINE: 'EIGENE_FORMEL_ONLINE' as ApplikationEnum,
        GEFPORTAL: 'GEF_PORTAL' as ApplikationEnum,
        GSIHPV: 'GSI_HPV' as ApplikationEnum
    };
}

