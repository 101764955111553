<div *ngIf="benutzer">
  <h1>
    {{ 'berechtigung.beantragen.hpv.titel' | translate}}
  </h1>
  <app-berechtigung-beantragen-betriebe-search (onBeantrageBerechtigung)="showSelectRoleDialog($event[0])" applikation="GSI_HPV" [showGlnSearch]="false">
  </app-berechtigung-beantragen-betriebe-search>
</div>

<br>
<p [innerHTML]="'berechtigung.beantragen.hpv.hilfe_text' | translate"></p>
<br>
<button class="btn btn-primary" style="float: left; display: inline;" (click)="navigateBack()">
  <i class="fa fa-arrow-left" aria-hidden="true"></i>
  <span translate="berechtigung.beantragen.button.zurueck"></span>
</button>

<app-fullscreen-spinner [show]="loading" [transparentForDurationMs]="50"></app-fullscreen-spinner>
