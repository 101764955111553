import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {BenutzerDTO, BenutzerService} from "../../core/api";
import {Router} from "@angular/router";
import {NotificationService} from "../../core/notification/notification.service";
import {ApplikationEnum} from "../../core/api/model/applikationEnum";
import {ApplicationService} from "../../common/application.service";

@Component({
  selector: 'app-berechtigung-beantragen',
  templateUrl: './berechtigung-beantragen.component.html',
  styleUrls: ['./berechtigung-beantragen.component.scss']
})
export class BerechtigungBeantragenComponent implements OnInit {

  public ApplikationenEnum = ApplikationEnum;
  public selectedAnwendung: String;
  public anwendungen: Array<String> = new Array<String>();
  public benutzer: BenutzerDTO;

  constructor(public translateService: TranslateService, private benutzerService: BenutzerService, private router: Router,
              private notificationService: NotificationService, private applikationService: ApplicationService) {
  }

  ngOnInit() {
    this.benutzerService.self().subscribe(
      benutzerDTO => this.benutzer = benutzerDTO,
      error => this.notificationService.handleErrorShort("error.benutzer.self")
    );
    this.initAnwendungen();
  }

  initAnwendungen() {
    this.anwendungen = Object.values(ApplikationEnum).filter(a => a !== ApplikationEnum.GEFPORTAL && this.applikationService.isApplicationActive(a));
    if (this.anwendungen.length == 1) {
      this.selectedAnwendung = this.anwendungen[0];
    }
  }

  onSelectedAnwendungChange() {
    if (this.selectedAnwendung == this.ApplikationenEnum.EIGENEFORMELONLINE) {
      this.router.navigate(['/berechtigung/beantragen/efo']);
    } else if (this.selectedAnwendung == this.ApplikationenEnum.HPV) {
      this.router.navigate(['/berechtigung/beantragen/hpv']);
    }
  }

  navigateBack() {
    this.router.navigate(['/home']);
  }
}
