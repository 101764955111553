/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-header.component.ngfactory";
import * as i2 from "./app-header.component";
import * as i3 from "bedag-angular-components";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./auth/auth.service";
import * as i6 from "@angular/router";
import * as i7 from "./core/notification/alert-list.component.ngfactory";
import * as i8 from "./core/notification/alert-list.component";
import * as i9 from "./core/notification/alert-holder.service";
import * as i10 from "@angular/common";
import * as i11 from "./app-footer/app-footer.component.ngfactory";
import * as i12 from "./app-footer/app-footer.component";
import * as i13 from "./core/api/api/info.service";
import * as i14 from "./app.component";
import * as i15 from "@angular/platform-browser";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i1.View_AppHeaderComponent_0, i1.RenderType_AppHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i2.AppHeaderComponent, [i3.AlertHolder, i4.TranslateService, i5.AuthenticationService, i6.Router], null, null), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "bsg-content-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\n  "])), (_l()(), i0.ɵeld(5, 0, null, null, 7, "main", [["id", "content"], ["role", "main"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-alert-list", [], null, null, null, i7.View_AlertListComponent_0, i7.RenderType_AlertListComponent)), i0.ɵdid(8, 49152, null, 0, i8.AlertListComponent, [i9.AlertHolderService, i10.JsonPipe], null, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(10, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(11, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵted(-1, null, ["\n  "])), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(15, 0, null, null, 1, "app-footer", [], null, null, null, i11.View_AppFooterComponent_0, i11.RenderType_AppFooterComponent)), i0.ɵdid(16, 114688, null, 0, i12.AppFooterComponent, [i5.AuthenticationService, i13.InfoService, i4.TranslateService], null, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 11, 0); _ck(_v, 16, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 114688, null, 0, i14.AppComponent, [i4.TranslateService, i15.Title], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
