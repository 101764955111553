<div *ngIf="benutzer">
  <h1>{{ 'berechtigung.beantragen.efo.titel' | translate}}</h1>
  <app-berechtigung-beantragen-betriebe-search (onBeantrageBerechtigung)="showSelectRoleDialog($event[0])"
                                               applikation="EIGENE_FORMEL_ONLINE"></app-berechtigung-beantragen-betriebe-search>
</div>

<br><br>
<button class="btn btn-primary" style="float: left; display: inline;" (click)="navigateBack()">
  <i class="fa fa-arrow-left" aria-hidden="true"></i>
  <span translate="berechtigung.beantragen.button.zurueck"></span>
</button>

<app-fullscreen-spinner [show]="loading" [transparentForDurationMs]="50"></app-fullscreen-spinner>
