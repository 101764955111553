import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, AbstractControl } from '@angular/forms';

/**
 * Validates this field contains an integer. Only allows positive integers by default. To allow
 * negative ones too, set [allowNegativeValues]="true"
 * Also prevents input, copy&paste and drag&drop of invalid content (as described here:
 * https://codeburst.io/digit-only-directive-in-angular-3db8a94d80c3).
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[integer]',
  providers: [{provide: NG_VALIDATORS, useExisting: IntegerDirective, multi: true}]
})
export class IntegerDirective implements Validator {
  private readonly RegexNegativeDigitsOnly = /[-]?\d+/g;
  private readonly RegexDigitsOnly = /\d+/g;

  // tslint:disable-next-line: no-input-rename
  @Input("allowNegativeValues")
  public allowNegativeValues = false;

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  private inputElement: HTMLElement;

  constructor(private el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  public validate(control: AbstractControl): ValidationErrors {
    let errors = null;

    if (control && control.value !== undefined && control.value !== null && control.value.length > 0) {
      if (parseInt(control.value, 10) !== parseFloat(control.value)) {
        errors = {
          integer: false
        };
      }
    }
    return errors;
  }

  // prevent non-numeric keys from being pressed
  @HostListener('keydown', ['$event'])
  public onKeyDown(e: KeyboardEvent) {
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter
      this.navigationKeys.indexOf(e.key) !== -1 ||
      // Allow minus sign if necessary
      (this.allowNegativeValues && e.key === '-') ||
      // Allow: Ctrl+A
      (e.key === 'a' && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.key === 'c' && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (e.key === 'v' && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.key === 'x' && e.ctrlKey === true) ||
      // Cmd+A (Mac)
      (e.key === 'a' && e.metaKey === true) ||
      // Cmd+C (Mac)
      (e.key === 'c' && e.metaKey === true) ||
      // Cmd+V (Mac)
      (e.key === 'v' && e.metaKey === true) ||
      // Cmd+X (Mac)
      (e.key === 'x' && e.metaKey === true)
    ) {
      return;  // let it happen
    }

    // if anything but a number is pressed, stop the event
    if (isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  // prevent invalid characters from being pasted
  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const inputString = event.clipboardData.getData('text/plain');

    const matchedInput = this.extractDigits(inputString);
    if (matchedInput) {
      document.execCommand('insertText', false, matchedInput);
    }
  }

  // prevent invalid characters from being dropped (e.g. from another input field)
  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent) {
    event.preventDefault();
    const inputString = event.dataTransfer.getData('text');
    this.inputElement.focus();

    const matchedInput = this.extractDigits(inputString);
    if (matchedInput) {
      document.execCommand('insertText', false, matchedInput);
    }
  }

  private extractDigits(inputString: string) {
    let matchedInput = null;
    if (this.allowNegativeValues) {
      // match all digits, optionally with a leading minus sign
      matchedInput = inputString.match(this.RegexNegativeDigitsOnly);
    } else {
      matchedInput = inputString.match(this.RegexDigitsOnly);
    }
    if (matchedInput && matchedInput.length > 0) {
      return matchedInput[0];
    }
    return undefined;
  }
}
