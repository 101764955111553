<!-- Rollenauswahl / GLN-Input -->
<form style="width: 100%;">
  <div>
    <label for="hpvRolle" class="label" translate="berechtigung.verwalten.label.rolle"></label>
    <select
      id="hpvRolle"
      name="hpvRolle"
      class="form-control input"
      (ngModelChange)="onSelectedRolleChange()"
      [(ngModel)]="selectedRolle">
      <option></option>
      <option *ngFor="let rolle of hpvRollenAuswahl" [ngValue]="rolle">{{'benutzer.rolle.' + rolle | translate}}</option>
    </select>
  </div>
  <div *ngIf="HPV_ROLLEN.RolleEnum.HPVPRAXISLEITUNG == selectedRolle" style="margin-top: 20px;">
    <label for="gln" class="label">{{ 'berechtigung.verwalten.hpv.gln' | translate }}</label>
    <input type="text" id="gln" name="gln" [(ngModel)]="gln" minlength="13" maxlength="13">
  </div>
</form>

<!-- Betriebesearch -->
<div *ngIf="selectedRolle" style="margin-top: 20px;">
  <app-rollen-verwalten-betriebe-search [showSearchResults]="true" (onSelectBetriebEvent)="addRolle($event[0])" [bearbeitenderBenutzer]="bearbeitenderBenutzer" applikation="GSI_HPV">
  </app-rollen-verwalten-betriebe-search>
</div>

<!-- HPV Benutzer-Rollenliste -->
<div class="w100" style="margin-top: 20px;">
  <h3 translate="berechtigung.verwalten.hpv.titel_aktuelle_rollen"></h3>
  <div>
    <div style="float: left;">
      <label for="anzahlResultate" class="label" translate="global.zeige"></label>
      <select
        class="select form-control"
        id="anzahlResultate"
        name="anzahlResultate" [(ngModel)]="itemsPerPage">
        <option [ngValue]="5">5</option>
        <option [ngValue]="10">10</option>
        <option [ngValue]="15">15</option>
        <option [ngValue]="20">20</option>
      </select>
    </div>
    <div style="float: right; min-width: 260px;">
      <input class="form-control input-search" type="text" id="searchBenutzer" name="searchBenutzer" [(ngModel)]="filter">
      <label for="searchBenutzer" class="label-search" translate="global.suche"></label>
    </div>
  </div>
  <table class="table">
    <thead>
    <tr>
      <th translate="berechtigung.verwalten.label.betrieb"></th>
      <th translate="berechtigung.verwalten.label.rolle"></th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="benutzerHpvRollen.length == 0">
      <td></td>
      <td translate="berechtigung.verwalten.keine.berechtigungen"></td>
      <td></td>
    </tr>
    <tr *ngFor="let rolle of benutzerHpvRollen | filter: filter | paginate: { id:'hpv_rollen',itemsPerPage: itemsPerPage, currentPage: page }">
    <td>{{ rolle.betrieb?.name1 }}</td>
      <td>{{ 'benutzer.rolle.' + rolle.rolle | translate }}</td>
      <td>
        <button
          class="btn btn-primary btn-sm"
          (click)="bestaetigeLoeschen(rolle)"><i class="far fa-trash-alt"></i></button>
      </td>
    </tr>
    </tbody>
  </table>
  <pagination-controls (pageChange)="page = $event" previousLabel="" id="hpv_rollen" class="pagination-control"
                       nextLabel=""></pagination-controls>
</div>
