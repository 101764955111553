import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {BetriebeListComponent} from "./betriebe-list/betriebe-list.component";
import {BetriebeDetailComponent} from './betriebe-detail/betriebe-detail.component';
import {TranslateModule} from "@ngx-translate/core";
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {Ng2OrderModule} from "ng2-order-pipe";
import {NgxPaginationModule} from "ngx-pagination";
import {FormsModule} from "@angular/forms";
import {CommonComponentsModule} from "../common/common-components.module";

const routes: Routes = [
  {path: "betriebe", component: BetriebeListComponent},
  {path: "betriebe/:id", component: BetriebeDetailComponent},
];

@NgModule({
  declarations: [
    BetriebeListComponent,
    BetriebeDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule.forRoot(),
    Ng2SearchPipeModule,
    Ng2OrderModule,
    NgxPaginationModule,
    FormsModule,
    CommonComponentsModule
  ]
})
export class BetriebeModule {
}
