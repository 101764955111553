/**
 * GEF Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BetriebDTO { 
    id?: object;
    beproId?: number;
    name1?: string;
    name2?: string;
    strasse?: string;
    postfach?: string;
    plz?: string;
    ort?: string;
    betriebArt: BetriebDTO.BetriebArtEnum;
    telefon?: string;
    email?: string;
    bur?: number;
    uid?: string;
    gln?: string;
    bewilligungActive?: boolean;
    korrespondenzSprache?: BetriebDTO.KorrespondenzSpracheEnum;
    betriebArtTranslation?: string;
    applikation: BetriebDTO.ApplikationEnum;
}
export namespace BetriebDTO {
    export type BetriebArtEnum = 'ANDERER' | 'BETRIEB_MIT_SWISSMEDIC_BEWILLIGUNG' | 'DROGERIE' | 'OEFFENTLICHE_APOTHEKE' | 'SPITALAPOTHEKE' | 'PRAXISADRESSE' | 'PA_ARZTPRAXIS';
    export const BetriebArtEnum = {
        ANDERER: 'ANDERER' as BetriebArtEnum,
        BETRIEBMITSWISSMEDICBEWILLIGUNG: 'BETRIEB_MIT_SWISSMEDIC_BEWILLIGUNG' as BetriebArtEnum,
        DROGERIE: 'DROGERIE' as BetriebArtEnum,
        OEFFENTLICHEAPOTHEKE: 'OEFFENTLICHE_APOTHEKE' as BetriebArtEnum,
        SPITALAPOTHEKE: 'SPITALAPOTHEKE' as BetriebArtEnum,
        PRAXISADRESSE: 'PRAXISADRESSE' as BetriebArtEnum,
        PAARZTPRAXIS: 'PA_ARZTPRAXIS' as BetriebArtEnum
    };
    export type KorrespondenzSpracheEnum = 'DE' | 'FR' | 'IT' | 'RT' | 'EN' | 'UNK';
    export const KorrespondenzSpracheEnum = {
        DE: 'DE' as KorrespondenzSpracheEnum,
        FR: 'FR' as KorrespondenzSpracheEnum,
        IT: 'IT' as KorrespondenzSpracheEnum,
        RT: 'RT' as KorrespondenzSpracheEnum,
        EN: 'EN' as KorrespondenzSpracheEnum,
        UNK: 'UNK' as KorrespondenzSpracheEnum
    };
    export type ApplikationEnum = 'EIGENE_FORMEL_ONLINE' | 'GEF_PORTAL' | 'GSI_HPV';
    export const ApplikationEnum = {
        EIGENEFORMELONLINE: 'EIGENE_FORMEL_ONLINE' as ApplikationEnum,
        GEFPORTAL: 'GEF_PORTAL' as ApplikationEnum,
        GSIHPV: 'GSI_HPV' as ApplikationEnum
    };
}

