import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BenutzerDTO, BenutzerRolleDTO, BetriebDTO, BetriebeService, SearchBetriebDTO} from "../../core/api";
import {BetriebRolle} from "../betrieb-rolle";
import {NotificationService} from "../../core/notification/notification.service";
import {TranslateService} from "@ngx-translate/core";
import ApplikationEnum = SearchBetriebDTO.ApplikationEnum;

@Component({
  selector: 'app-rollen-verwalten-betriebe-search',
  templateUrl: './rollen-verwalten-betriebe-search.component.html',
  styleUrls: ['./rollen-verwalten-betriebe-search.component.scss']
})
export class RollenVerwaltenBetriebeSearchComponent implements OnInit {

  @Input() public applikation: ApplikationEnum;

  @Input() public showSearchResults: boolean;

  @Input() private betriebRollenListe: Array<BetriebRolle>;

  @Input() private bearbeitenderBenutzer: BenutzerDTO;

  @Output() onSelectBetriebEvent: EventEmitter<any> = new EventEmitter();

  private betriebeAuswahl: Array<BetriebDTO> = new Array<BetriebDTO>();

  public searchBetriebDTO: SearchBetriebDTO = {applikation: this.applikation};

  filter: String;
  key: string = ''; // default sorting column
  reverse: boolean = false;
  page: number = 1;
  itemsPerPage: number = 10;

  constructor(public translateService: TranslateService, private notificationService: NotificationService, private betriebService: BetriebeService) {
  }

  ngOnInit() {
    if (this.betriebRollenListe == undefined) {
      this.betriebRollenListe = new Array<BetriebRolle>();
    }
    this.initSearchBetriebDTO();
    if (!this.showSearchFrom()) {
      // admins can search, others just see the Betriebe they are authorized for
      this.betriebeAuswahl = this.bearbeitenderBenutzer.rollen
        .filter(benutzerRolle => benutzerRolle.betrieb)
        .map(benutzerRolle => benutzerRolle.betrieb)
        .filter(betrieb => betrieb.applikation === this.applikation)
        // make values unique (keep them if they are the first occurence in the array)
        .filter((betrieb, index, self) => self.findIndex(v => v.id === betrieb.id) === index);
      this.showSearchResults = true;
    }
  }

  initSearchBetriebDTO() {
    this.searchBetriebDTO.applikation = this.applikation;
    this.searchBetriebDTO.ort = null;
    this.searchBetriebDTO.name1 = null;
    this.searchBetriebDTO.gln = null;
  }

  searchBetrieb() {
    this.setAllInputFieldsWithEmptyStringNull();
    this.betriebService.searchBetriebFilteredByRolle(this.searchBetriebDTO).subscribe(
      success => {
        this.betriebeAuswahl = success;
        this.showSearchResults = true;
      }, () => {
        this.betriebeAuswahl = new Array<BetriebDTO>();
        this.showSearchResults = true;
        this.notificationService.handleErrorShort("error.betrieb.search");
      }
    )
  }

  setAllInputFieldsWithEmptyStringNull() {
    if (this.searchBetriebDTO.ort != null && this.searchBetriebDTO.ort.trim().length == 0) {
      this.searchBetriebDTO.ort = null;
    }
    if (this.searchBetriebDTO.name1 != null && this.searchBetriebDTO.name1.trim().length == 0) {
      this.searchBetriebDTO.name1 = null;
    }
    if (this.searchBetriebDTO.gln != null && this.searchBetriebDTO.gln.trim().length == 0) {
      this.searchBetriebDTO.gln = null;
    }
  }

  getSortedBetriebe(): Array<BetriebDTO> {
    return this.betriebeAuswahl.sort(RollenVerwaltenBetriebeSearchComponent.betriebNameComparator);
  }

  private static betriebNameComparator(betrieb1: BetriebDTO, betrieb2: BetriebDTO): number {
    if (betrieb1.name1 < betrieb2.name1) return -1;
    if (betrieb1.name1 > betrieb2.name1) return 1;
    return 0;
  }

  hasBetriebAlreadyBeenAdded(betrieb: BetriebDTO): Boolean {
    let added: Boolean = false;
    this.betriebRollenListe.forEach(
      rolle => {
        if (rolle.betriebId === betrieb.id) {
          added = true
        }
      });
    return added;
  }

  onSelectBetrieb(betrieb: BetriebDTO) {
    this.onSelectBetriebEvent.emit([betrieb]);
  }

  showSearchFrom(): boolean {
    if (this.applikation === ApplikationEnum.EIGENEFORMELONLINE) {
      return this.benutzerHasAnyRoleOf([BenutzerRolleDTO.RolleEnum.ADMINISTRATOR, BenutzerRolleDTO.RolleEnum.EFOADMIN]);
    } else if (this.applikation === ApplikationEnum.GSIHPV) {
      return this.benutzerHasAnyRoleOf([BenutzerRolleDTO.RolleEnum.ADMINISTRATOR, BenutzerRolleDTO.RolleEnum.HPVADMIN]);
    } else {
      return this.benutzerHasAnyRoleOf([BenutzerRolleDTO.RolleEnum.ADMINISTRATOR]);
    }
  }

  private benutzerHasAnyRoleOf(allowedRoles: BenutzerRolleDTO.RolleEnum[]): boolean {
    return this.bearbeitenderBenutzer.rollen.some(
      r => allowedRoles.indexOf(r.rolle) > -1
    );
  }
}
