import {Component, OnInit} from '@angular/core';
import {AntragBerechtigungDTO, BenutzerDTO, BenutzerRolleDTO, BenutzerService, BetriebDTO} from "../../core/api";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {NotificationService} from "../../core/notification/notification.service";
import {BerechtigungBeantragenSelectRoleComponent} from "../berechtigung-beantragen-select-role/berechtigung-beantragen-select-role.component";
import {ApplikationEnum} from "../../core/api/model/applikationEnum";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import RolleEnum = BenutzerRolleDTO.RolleEnum;

@Component({
  selector: 'app-berechtigung-beantragen-hpv',
  templateUrl: './berechtigung-beantragen-hpv.component.html',
  styleUrls: ['./berechtigung-beantragen-hpv.component.scss']
})
export class BerechtigungBeantragenHpvComponent implements OnInit {

  public benutzer: BenutzerDTO;
  public rollen: Array<String>;

  loading = false;

  constructor(public translateService: TranslateService, private benutzerService: BenutzerService, private router: Router,
              private notificationService: NotificationService, private modalService: NgbModal) {
  }

  ngOnInit() {
    this.benutzerService.self().subscribe(
      benutzerDTO => this.benutzer = benutzerDTO,
      error => this.notificationService.handleErrorShort("error.benutzer.self")
    );
    this.rollen = BerechtigungBeantragenHpvComponent.getHPVBenutzerRollen();
  }

  private static getHPVBenutzerRollen(): Array<BenutzerRolleDTO.RolleEnum> {
    return Object.values(BenutzerRolleDTO.RolleEnum).filter(rolle => rolle.includes("HPV_PRAXISLEITUNG"));
  }

  showSelectRoleDialog(betrieb: BetriebDTO) {
    const dialog = this.modalService.open(BerechtigungBeantragenSelectRoleComponent);
    dialog.componentInstance.betrieb = betrieb;
    dialog.componentInstance.applikation = ApplikationEnum.HPV;
    dialog.result.then((result) => {
      this.beantrageBerechtigungBetrieb(betrieb, result.role, result.gln);
    }, () => {
      // just prevent an error on the console because the promise is rejected
    });
  }

  private beantrageBerechtigungBetrieb(betrieb: BetriebDTO, beantragteRolle: RolleEnum, gln: string) {
    this.loading = true;
    let antrag: AntragBerechtigungDTO = {benutzerId: this.benutzer.id, betriebId: betrieb.id, applikation: ApplikationEnum.HPV, rolle: beantragteRolle, gln: gln};
    this.benutzerService.beantrageBerechtigung(antrag).subscribe(
      success => {
        this.loading = false;
        this.notificationService.handleSuccess("erfolg.antrag.berechtigung", {betrieb: betrieb.name1})
      }, error => {
        this.loading = false;
        this.notificationService.handleErrorShort(error.error);
      }
    )
  }

  navigateBack() {
    this.router.navigate(['/berechtigung/beantragen']);
  }
}
