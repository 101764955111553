import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {BenutzerListComponent} from "./benutzer-list/benutzer-list.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ConfirmDialogModule} from "../core/confirm-dialog/confirm-dialog.module";
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {TranslateModule} from "@ngx-translate/core";
import {Ng2OrderModule} from "ng2-order-pipe";
import {NgxPaginationModule} from "ngx-pagination";
import {ProfileComponent} from "./profile/profile.component";
import { BenutzerDetailComponent } from './benutzer-detail/benutzer-detail.component';
import {CommonComponentsModule} from "../common/common-components.module";


const routes: Routes = [
  {path: "benutzer", component: BenutzerListComponent},
  {path: "benutzer/detail/:id", component: BenutzerDetailComponent},
  {path: "benutzer/profil", component: ProfileComponent}
];


@NgModule({
  bootstrap: [BenutzerListComponent],
  declarations: [
    BenutzerListComponent,
    ProfileComponent,
    BenutzerDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    Ng2SearchPipeModule,
    TranslateModule.forRoot(),
    Ng2OrderModule,
    NgxPaginationModule,
    CommonComponentsModule
  ]
})
export class BenutzerModule {
}
