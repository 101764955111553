import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BenutzerDTO, BenutzerRolleDTO, BenutzerService, BetriebDTO, BetriebeService, CreateRolleDTO, UpdateRolleDTO} from "../../core/api";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";
import {ConfirmDialogService} from "../../core/confirm-dialog/confirm-dialog.service";
import {NotificationService} from "../../core/notification/notification.service";
import {BetriebRolle} from "../betrieb-rolle";

@Component({
  selector: 'app-rollen-verwalten-efo',
  templateUrl: './rollen-verwalten-efo.component.html',
  styleUrls: ['./rollen-verwalten-efo.component.scss']
})
export class RollenVerwaltenEfoComponent implements OnInit, OnChanges {

  @Input() public benutzer: BenutzerDTO;

  @Input() public blockInput: boolean;

  @Input() public bearbeitenderBenutzer: BenutzerDTO;

  @Output() onDeleteRolle: EventEmitter<any> = new EventEmitter();

  public betriebRollenListe: Array<BetriebRolle> = new Array<BetriebRolle>();
  public showSearchResults: Boolean = false;
  public betriebeAuswahl: Array<BetriebDTO> = new Array<BetriebDTO>();

  private currentBenutzerBetriebsleiterRolle: BenutzerRolleDTO;

  constructor(public translateService: TranslateService, private benutzerService: BenutzerService, private route: ActivatedRoute, private betriebService: BetriebeService,
              private confirmDialogService: ConfirmDialogService, private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.resetBetriebRollenListe();

    this.showSearchResults = false;
    if (!this.isBearbeitenderBenutzerEfoOderPortalAdmin() && this.isBearbeitenderBenutzerBetriebsleiter()) {
      this.setBetriebAuswahlForBetriebsleiter();
      this.showSearchResults = true;
    }
  }

  public getRollenAuswahlenOptions(betrieb: BetriebRolle) {
    this.blockInput = false;
    const betriebsRollenAuswahlOptionen = new Array();
    if (this.currentBenutzerBetriebsleiterRolle == null || (this.currentBenutzerBetriebsleiterRolle && betrieb.betriebId === this.currentBenutzerBetriebsleiterRolle.betrieb.id)) {
      betriebsRollenAuswahlOptionen.push(CreateRolleDTO.RolleEnum.EFOBETRIEBSLEITER);
    }
    betriebsRollenAuswahlOptionen.push(CreateRolleDTO.RolleEnum.EFOBETRIEBSMITARBEITER);
    return betriebsRollenAuswahlOptionen;
  }

  private setBetriebAuswahlForBetriebsleiter() {
    this.betriebeAuswahl = new Array<BetriebDTO>();
    let blRollen: Array<any> = this.bearbeitenderBenutzer.rollen.filter(
      r => r.rolle === ("EFO_BETRIEBSLEITER")
    );
    blRollen.forEach(rolle => {
      this.betriebService.getBetriebById(rolle.betrieb.id).subscribe(
        success => this.betriebeAuswahl.push(success),
        error => this.notificationService.handleErrorShort(error.error)
      )
    });
  }

  private isBearbeitenderBenutzerEfoOderPortalAdmin(): boolean {
    return this.bearbeitenderBenutzer.rollen.filter(
      r => r.rolle === BenutzerRolleDTO.RolleEnum.EFOADMIN || r.rolle === BenutzerRolleDTO.RolleEnum.ADMINISTRATOR
    ).length > 0;
  }

  isBearbeitenderBenutzerBetriebsleiter(): boolean {
    return this.bearbeitenderBenutzer.rollen.filter(
      r => r.rolle === BenutzerRolleDTO.RolleEnum.EFOBETRIEBSLEITER
    ).length > 0;
  }

  fuegeBetriebHinzu(betrieb: BetriebDTO) {
    let obj = {betriebName: betrieb.name1, betriebId: betrieb.id, rolle: null, isUpdate: false};
    this.betriebRollenListe.push(obj);
    this.betriebRollenListe.sort(RollenVerwaltenEfoComponent.betriebRolleComparator);
  }

  saveEfoRolle(betriebRolle: BetriebRolle) {
    if (betriebRolle.isUpdate) {
      this.updateBetriebRolle(betriebRolle);
    } else {
      this.saveBetriebRolle(betriebRolle);
    }
  }

  updateBetriebRolle(betriebRolle: BetriebRolle) {
    let updateRolle: UpdateRolleDTO = {
      benutzerId: this.benutzer.id,
      betriebId: betriebRolle.betriebId,
      rolle: betriebRolle.rolle
    };
    this.blockInput = true;
    this.benutzerService.updateRolle(updateRolle).subscribe(
      success => {
        this.benutzer = success;
        this.resetBetriebRollenListe();
        this.blockInput = false;
        this.notificationService.handleSuccess("erfolg.benutzer.rolle.speichern");
      }, error => {
        this.blockInput = false;
        this.notificationService.handleErrorShort(error.error);
      }
    );
  }

  saveBetriebRolle(betriebRolle: BetriebRolle) {
    let createRolle: CreateRolleDTO = {
      benutzerId: this.benutzer.id,
      betriebId: betriebRolle.betriebId,
      rolle: betriebRolle.rolle
    };
    this.blockInput = true;
    this.benutzerService.addRolle(createRolle).subscribe(
      success => {
        this.benutzer = success;
        this.resetBetriebRollenListe();
        this.blockInput = false;
        this.notificationService.handleSuccess("erfolg.benutzer.rolle.speichern");
      }, error => {
        this.blockInput = false;
        this.notificationService.handleErrorShort(error.error);
      }
    );
  }

  private resetBetriebRollenListe() {
    this.blockInput = false;
    this.betriebRollenListe = new Array<BetriebRolle>();
    this.benutzer.rollen.forEach(
      rolle => {
        if (rolle.rolle.includes("EFO_") && rolle.betrieb != null) {
          let obj = {
            betriebName: rolle.betrieb.name1,
            betriebId: rolle.betrieb.id,
            rolle: rolle.rolle,
            isUpdate: true
          };
          this.betriebRollenListe.push(obj);
        }
      });
    this.betriebRollenListe.sort(RollenVerwaltenEfoComponent.betriebRolleComparator);
  }

  getSortedBetriebRolle(): Array<BetriebRolle> {
    return this.betriebRollenListe.sort(RollenVerwaltenEfoComponent.betriebRolleComparator);
  }

  private static betriebRolleComparator(rolle1: BetriebRolle, rolle2: BetriebRolle): number {
    if (rolle1.betriebName < rolle2.betriebName) return -1;
    if (rolle1.betriebName > rolle2.betriebName) return 1;
    return 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetBetriebRollenListe();
    this.currentBenutzerBetriebsleiterRolle = this.benutzer.rollen.find(rolle => rolle.rolle === "EFO_BETRIEBSLEITER");
  }

  bestaetigeLoeschen(rolle: BenutzerRolleDTO) {
    this.onDeleteRolle.emit([rolle]);
  }
}
