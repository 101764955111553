import {Injectable} from '@angular/core';
import {ApplikationEnum} from "../core/api/model/applikationEnum";
import {environment} from "../../environments/environment";
import {KachelEnum} from "../core/api/model/kachelEnum";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  private activeApplications: Array<any>;
  private activeKacheln: Array<any>;

  constructor() {
    let activeApplicationStringArray = environment.activeApplications;
    if (activeApplicationStringArray) {
      this.activeApplications = activeApplicationStringArray.map(a => ApplikationEnum[a]);
    } else {
      this.activeApplications = Object.values(ApplikationEnum).map(ae => ae);
    }
    let activeKachelnStringArray = environment.activeKacheln;
    if (activeKachelnStringArray) {
      this.activeKacheln = activeKachelnStringArray.map(k => KachelEnum[k]);
    } else {
      this.activeKacheln = Object.values(KachelEnum).map(ke => ke);
    }
  }

  public isApplicationActive(applikationEnum: ApplikationEnum): boolean {
    return this.activeApplications.includes(applikationEnum);
  }

  public isKachelActive(kachelEnum: KachelEnum): boolean {
    return this.activeKacheln.includes(kachelEnum);
  }
}
