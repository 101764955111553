import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {BenutzerDTO, BenutzerService} from "../api";
import {NotificationService} from "../notification/notification.service";

@Injectable()
export class CurrentUserResolver implements Resolve<BenutzerDTO> {

  constructor(private benutzerService: BenutzerService, private notificationService: NotificationService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<BenutzerDTO> {
    return new Observable<BenutzerDTO>((observer) => {
      this.benutzerService.self()
        .subscribe((currentUser: BenutzerDTO) => {
          observer.next(currentUser);
          observer.complete();
        }, (error) => {
          this.notificationService.handleErrorShort("error.benutzer.self")
          observer.error(error);
        });
    });
  }
}
