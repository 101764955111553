/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./alert.component";
var styles_AlertComponent = [];
var RenderType_AlertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
function View_AlertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "button", [["class", "close"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.fading = true;
        var pd_0 = (_co.dismiss.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"])), (_l()(), i0.ɵted(-1, null, ["\n      "]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform("Close")); _ck(_v, 0, 0, currVal_0); }); }
export function View_AlertComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["role", "alert"]], [[8, "className", 0], [2, "alert-dismissible", null], [2, "show", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertComponent_1)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n      "])), (_l()(), i0.ɵeld(6, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n      "])), i0.ɵncd(null, 0), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, null, ["\n  "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.dismissible; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "alert fade alert-", _co.type, ""); var currVal_1 = _co.dismissible; var currVal_2 = !_co.fading; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i0.ɵinlineInterpolate(1, "fas ", _co.icons[_co.type], " mr-2"); _ck(_v, 6, 0, currVal_4); }); }
export function View_AlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i3.AlertComponent, [], null, null)], null, null); }
var AlertComponentNgFactory = i0.ɵccf("app-alert", i3.AlertComponent, View_AlertComponent_Host_0, { type: "type" }, { dismiss: "dismiss" }, ["*"]);
export { AlertComponentNgFactory as AlertComponentNgFactory };
