import {Component, OnInit} from '@angular/core';
import {BenutzerDTO, BenutzerRolleDTO, BenutzerService} from "../../core/api";
import {TranslateService} from "@ngx-translate/core";
import {NotificationService} from "../../core/notification/notification.service";
import {AuthenticationService} from "../../auth/auth.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: BenutzerDTO;
  rollen: String;

  constructor(private translateService: TranslateService, private benutzerService: BenutzerService, private notificationService: NotificationService, private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.benutzerService.self().subscribe(
      response => {
        this.user = response;
        this.rollen = this.getRollenString();
      }, error => this.notificationService.handleErrorShort("error.benutzer.self")
    );
  }

  getRollenString() {
    return this.user.rollen.map(rolle => this.createRolleString(rolle)).join(', ');
  }

  createRolleString(rolle: BenutzerRolleDTO): String {
    return this.translateService.instant("benutzer.rolle." + rolle.rolle) + (rolle.betrieb != null ? " (" + rolle.betrieb.name1 + ")" : "");
  }

}
