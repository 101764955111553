import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges} from '@angular/core';
import {BenutzerDTO, BenutzerRolleDTO, BenutzerService, BetriebeService, CreateRolleDTO} from "../../core/api";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";
import {ConfirmDialogService} from "../../core/confirm-dialog/confirm-dialog.service";
import {NotificationService} from "../../core/notification/notification.service";

@Component({
  selector: 'app-rollen-verwalten-portal',
  templateUrl: './rollen-verwalten-portal.component.html',
  styleUrls: ['./rollen-verwalten-portal.component.scss']
})
export class RollenVerwaltenPortalComponent implements OnInit, OnChanges {

  @Input() public benutzer: BenutzerDTO;

  @Input() public bearbeitenderBenutzer: BenutzerDTO;

  @Input() public blockInput: boolean;

  @Output() onDeleteRolle: EventEmitter<any> = new EventEmitter();

  public applikationsRollenAuswahl: Array<any> = new Array<any>();
  public applikationsRolle: any;
  public portalRollenAuswahl: Array<CreateRolleDTO.RolleEnum> = new Array<CreateRolleDTO.RolleEnum>();
  public rollenListe: Array<BenutzerRolleDTO> = new Array<BenutzerRolleDTO>();

  constructor(public translateService: TranslateService, private benutzerService: BenutzerService, private route: ActivatedRoute, private betriebService: BetriebeService,
              private confirmDialogService: ConfirmDialogService, private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.applikationsRollenAuswahl = this.portalRollenAuswahl;

    this.portalRollenAuswahl.push(CreateRolleDTO.RolleEnum.ADMINISTRATOR);
    this.portalRollenAuswahl.push(CreateRolleDTO.RolleEnum.EFOADMIN);
    this.portalRollenAuswahl.push(CreateRolleDTO.RolleEnum.HPVADMIN);

    this.updateBenutzerPortalRollen(this.benutzer.rollen);
  }

  canManagePortalRollen(): boolean {
    return this.bearbeitenderBenutzer.rollen.map(r => r.rolle).includes(BenutzerRolleDTO.RolleEnum.ADMINISTRATOR);
  }

  doesBenutzerAlreadyHaveApplikationsRolle() {
    let added: Boolean = false;
    this.rollenListe.forEach(
      rolle => {
        if (rolle.rolle === this.applikationsRolle) {
          added = true
        }
      });
    return added;
  }

  saveApplikationsRolle() {
    let createRolle: CreateRolleDTO = {benutzerId: this.benutzer.id, betriebId: null, rolle: this.applikationsRolle};
    this.benutzerService.addRolle(createRolle).subscribe((benutzer) => {
      this.benutzer = benutzer;
      this.updateBenutzerPortalRollen(this.benutzer.rollen);
      this.notificationService.handleSuccess("erfolg.benutzer.rolle.speichern");
    }, error => this.notificationService.handleErrorShort(error.error));
  }

  private updateBenutzerPortalRollen(benutzerRollen: Array<BenutzerRolleDTO>) {
    this.blockInput = false;
    this.rollenListe = new Array<BenutzerRolleDTO>();
    benutzerRollen.forEach(
      rolle => {
        if (rolle.betrieb == null) {
          this.rollenListe.push(rolle);
        }
      });
  }

  bestaetigeLoeschen(rolle: BenutzerRolleDTO) {
    this.onDeleteRolle.emit([rolle]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const updatedBenutzer: SimpleChange = changes.benutzer;
    if (updatedBenutzer) {
      this.updateBenutzerPortalRollen(updatedBenutzer.currentValue.rollen);
    }
  }
}
