<div class="styleguide">
  <div class='header-oe'>
    <div class='signet-oe-2'>
      <img class='logo' src='../assets/img/Kanton-Bern.svg'>
      <div class='logo-caption' translate="header.logo.titel"></div>
    </div>
    <div class='service-nav'>
      <oidc-user-picker [languages]="languages" class="user-login-menu">
        <a class="dropdown-item" routerLink="/benutzer/profil" routerLinkActive="active" *ngIf="authService.isLoggedIn()">
          <span class="fas fa-user-circle fa-1 fa-fw" aria-hidden="true"></span>
          {{'header.benutzerprofil' | translate}}
        </a>
        <hr>
      </oidc-user-picker>
      <div class='language-selector'>
        <a class="language" [ngClass]="translateService.currentLang=='de' ? 'active': ''" href="javascript:" (click)="setLanguage('de')" translate="header.de"></a>
        <span>|</span>
        <a class="language" [ngClass]="translateService.currentLang=='fr' ? 'active': ''" href="javascript:" (click)="setLanguage('fr')" translate="header.fr"></a>
      </div>
    </div>
    <div class='main-nav'>
      <a href='#' class='main-menue i1' [routerLink]="['home']" routerLinkActive="active" translate="header.home"></a>
      <a href='#' class='main-menue i2' [routerLink]="['benutzer']" routerLinkActive="active" *ngIf="authService.isAdminOrLeitung() | async" translate="header.benutzer"></a>
      <a href='#' class='main-menue i3' [routerLink]="['betriebe']" routerLinkActive="active" *ngIf="authService.isAdminOrLeitung() | async" translate="header.betriebe"></a>
    </div>

    <div class="mobile-nav">
      <nav [ngClass]="translateService.currentLang=='de' ? 'german-mobile-nav' : 'french-mobile-nav'">
        <button [ngClass]="translateService.currentLang=='de' ? 'german-mobile-nav-button' : 'french-mobile-nav-button'" (click)="toggleMobileNav()">
          <span><i class="fa fa-bars fa-2x" aria-hidden="true"></i></span>
        </button>
        <ul *ngIf="show">
          <li>
            <a href='#' class='nav-link' [routerLink]="['home']" routerLinkActive="active" translate="header.home"></a>
          </li>
          <li *ngIf="authService.isAdminOrLeitung() | async">
            <a href='#' [routerLink]="['benutzer']" routerLinkActive="active" translate="header.benutzer"></a>
          </li>
          <li *ngIf="authService.isAdministrator() | async">
            <a href='#' [routerLink]="['betriebe']" routerLinkActive="active" translate="header.betriebe"></a>
          </li>
        </ul>
      </nav>
    </div>

  </div>
</div>

