/* Definition of all Kacheln that are available for Configuration */
export type KachelEnum = 'EIGENE_FORMEL_ONLINE' | 'GEF_PORTAL' | 'GSI_HPV';
export const KachelEnum = {
  EIGENEFORMELONLINE: 'EIGENE_FORMEL_ONLINE' as KachelEnum,
  GEFPORTAL: 'GEF_PORTAL' as KachelEnum,
  HPV: 'GSI_HPV' as KachelEnum,
  SIRONA: 'SIRONA' as KachelEnum,
  KITA: 'KITA' as KachelEnum,
  AVG: 'AVG' as KachelEnum,
  AEWB: 'AEWB' as KachelEnum
};
