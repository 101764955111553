<div class="container mt-2">
  <ng-container *ngIf="user">
    <div class="row">
      <div class="col-12 title" style="margin-top: 10px;">
        <i class="fas fa-user-circle fa-2x fa-fw" aria-hidden="true"></i>
        {{ "benutzer.profil.title" | translate}}
      </div>
    </div>
    <br>

    <!-- Add email when user object contains email data! -->
    <div class="row">
      <div class="col-6 mt-4 form-group">
        <label for="email">{{'benutzer.profil.email'|translate}}</label>
        <input id="email"
               type="text"
               class="form-control"
               name="email"
               [ngModel]="user.email"
               disabled>
      </div>
    </div>

    <div class="row">
      <div class="col-6 mt-4 form-group">
        <label for="userName">{{'benutzer.profil.username'|translate}}</label>
        <input id="userName"
               type="text"
               class="form-control"
               name="userName"
               [ngModel]="user.benutzerName"
               disabled>
      </div>
    </div>

    <div class="row">
      <div class="col-6 mt-4 form-group">
        <label for="firstName">{{'benutzer.profil.firstname' | translate}}</label>
        <input id="firstName"
               type="text"
               class="form-control"
               name="firstName"
               [ngModel]="user.vorname"
               disabled>
      </div>
    </div>

    <div class="row">
      <div class="col-6 mt-4 form-group">
        <label for="lastName">{{'benutzer.profil.lastname' | translate}}</label>
        <input id="lastName"
               type="text"
               class="form-control"
               name="lastName"
               [ngModel]="user.name"
               disabled>
      </div>
    </div>

    <div class="row">
      <div class="col-6 mt-4 form-group">
        <label for="rolle">{{'benutzer.profil.rollen'|translate}}</label>
        <textarea id="rolle"
                  type="text"
                  class="form-control"
                  name="rolle"
                  [ngModel]="rollen"
                  disabled></textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-4 mt-4 form-group">
        <button type="button" class="btn btn-primary" (click)="authService.logout()">
          <i class="fa fa-sign-in-alt" aria-hidden="true"></i>&nbsp;{{('Logout' | translate)}}
        </button>
      </div>
    </div>
  </ng-container>
</div>
