import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertHolderService {

  alerts: Alert[] = [];

  public success(message: string) {
    this.add({type: "success", message, error: null});
  }

  public info(message: string) {
    this.add({type: "info", message, error: null});
  }

  public warning(message: string) {
    this.add({type: "warning", message, error: null});
  }

  public danger(message: string, error: any) {
    this.add({type: "danger", message, error});
  }

  private add(alert: Alert) {
    this.alerts.push(alert);
    if (alert.type === "success" || alert.type === "info") {
      setTimeout(() => this.dismiss(alert), 5000);
    }
  }

  public dismiss(alert: Alert) {
    setTimeout(() => this.alerts = this.alerts.filter(a => a !== alert), 150);
  }
}


interface Alert {
  type: string;
  message: string;
  error: any;
}
